/*!
 * Material Design for Bootstrap 4
 * Version: MDB PRO 4.4.3
 *
 *
 * Copyright: Material Design for Bootstrap
 * https://mdbootstrap.com/
 *
 * Read the license: https://mdbootstrap.com/license/
 *
 *
 * Documentation: https://mdbootstrap.com/
 *
 * Getting started: https://mdbootstrap.com/getting-started/
 *
 * Tutorials: https://mdbootstrap.com/bootstrap-tutorial/
 *
 * Templates: https://mdbootstrap.com/templates/
 *
 * Support: https://mdbootstrap.com/support/
 *
 * Contact: office@mdbootstrap.com 
 *
 * Atribution: Animate CSS, Twitter Bootstrap, Materialize CSS, Normalize CSS, Waves JS, WOW JS, Toastr, Chart.js , Hammer.js
 *
 */

@charset "UTF-8";

@use "sass:math";
// Bootstrap //

@import "mdb/free/data/functions";
@import "mdb/free/data/variables-b4";

// MDB Framework //
@import "mdb/free/data/prefixer";
@import "mdb/free/data/mixins";
@import "mdb/free/data/colors";
@import "mdb/free/data/variables";
@import "mdb/free/global";
@import "mdb/free/animations";
@import "mdb/free/helpers";
@import "mdb/free/typography";
@import "mdb/free/buttons";
@import "mdb/free/cards-basic";
@import "mdb/free/dropdowns";
@import "mdb/free/navbar";
@import "mdb/free/pagination";
@import "mdb/free/badge";
@import "mdb/free/breadcrumb";
@import "mdb/free/modals";
@import "mdb/free/progress";
@import "mdb/free/carousel-basic";
@import "mdb/free/collapse";
@import "mdb/free/jumbotron";
@import "mdb/free/masks";
@import "mdb/free/waves";
@import "mdb/free/forms-basic";
@import "mdb/free/msc";
@import "mdb/free/footer";
@import "mdb/free/list-group";
@import "mdb/free/tables";

@import "mdb/pro/variables-pro";
@import "mdb/free/deprecated";
@import "mdb/pro/deprecated";
@import "mdb/pro/buttons-pro";
@import "mdb/pro/social-buttons";
@import "mdb/pro/tabs";
@import "mdb/pro/cards-pro";
@import "mdb/pro/dropdowns-pro";
@import "mdb/pro/navbars-pro";
@import "mdb/pro/scrollspy";
@import "mdb/pro/light-box";
@import "mdb/pro/chips";
@import "mdb/pro/msc";
@import "mdb/pro/radio";
@import "mdb/pro/checkbox";
@import "mdb/pro/material-select";
@import "mdb/pro/switch";
@import "mdb/pro/file-input";
@import "mdb/pro/range";
@import "mdb/pro/input-group";
@import "mdb/pro/autocomplete";
@import "mdb/pro/parallax";
@import "mdb/pro/side-nav";
@import "mdb/pro/ecommerce";
@import "mdb/pro/carousels-pro";
@import "mdb/pro/stepper";
@import "mdb/pro/blog";
@import "mdb/pro/toasts";
@import "mdb/pro/animations-pro";
@import "mdb/pro/charts-pro";
@import "mdb/pro/progress";
@import "mdb/pro/scrollbar";
@import "mdb/pro/skins";

@import "mdb/pro/date_picker/default";
@import "mdb/pro/date_picker/default.time";
@import "mdb/pro/date_picker/default.date";

@import "mdb/pro/sections";
@import "mdb/pro/sections/templates";
@import "mdb/pro/sections/features";
@import "mdb/pro/sections/social";
@import "mdb/pro/sections/blog";
@import "mdb/pro/sections/team";
@import "mdb/pro/sections/testimonials";
@import "mdb/pro/sections/projects";
@import "mdb/pro/sections/magazine";
@import "mdb/pro/sections/pricing";
@import "mdb/pro/sections/contacts";

// Custom Sass
@import "mdb/custom";

@import "./angular/mdb-angular"