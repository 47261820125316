// Fonts Path
$roboto-font-path: "../../../font/roboto/" !default;

// Full Palette
$enable_full_palette: true;

// $btn-floating-icon-margin: 5px;

//Tabs
$tabs-padding: 0.7rem;
$tabs-margin-x: 1rem;
$tabs-margin-y: -20px;
$pills-padding: 0.6rem;

// Spacers
$spacer:                     1rem !default;
$spacer-x:                   $spacer !default;
$spacer-y:                   $spacer !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 5)
) !default;


// Extreme Padding
$extreme-padding: (
  "xs": 0,
  "sm": 5%,
  "md": 10%,
  "lg": 15%,
  "xl": 20%
);

// Navbar
$font-weight: 300;

$navbar-light-toggler-icon: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !default;
$navbar-light-bg-active-color: rgba($black, .1) !default;
$navbar-light-hover-color: rgba($black, .75);

$navbar-dark-toggler-icon: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !default;
$navbar-dark-bg-active-color: rgba($white, .1) !default;
$navbar-dark-hover-color: rgba($white, .75);

$navbar-scrolling-padding: 12px;
$navbar-top-collapse-padding: 5px;
$navbar-flex-icons-padding-md: 6px;
$navbar-flex-icons-padding-lg: 3px;
$navbar-form-input-mr: 5px;
$navbar-form-input-mb: 1px;
$navbar-form-input-ml: 8px;
$navbar-form-input-height: 1rem;
$navbar-dropdown-font-size: 0.9375rem;
$navbar-dropdown-menu-padding: 10px;
$navbar-padding-dropdown: 1rem;
$navbar-avatar-height: 35px;
$navbar-double-small-padding: 4px;
$navbar-double-font-size: 15px;
$navbar-breadcrumb-dn-padding: 1rem;
// $navbar-button-collapse-top: 1px;
$navbar-button-collapse-left: 10px;
$navbar-button-collapse-font-size: 1.5rem;
$navbar-button-collapse-fs-sm: 1.4rem;
$navbar-button-collapse-mx: 10px;
// $navbar-button-collapse-mt: 1px;

//Dropdown
$dropdown-menu-padding: 0.5rem;
$dropdown-item-padding: 1rem;

// Badges
$badge-pill-padding-x: 0.6rem !default;
$badge-pill-border-radius: 10rem !default;

// Progress
$progress-bar-height: 4px;
$progress-bar-wider-height: 20px;
$progress-bar-narrower-height: 1px;
$progress-bar-margin-y: 1rem;
$progress-bar-bg-color: map-get($grey, lighten-3);

// Forms
// Text Inputs + Textarea
$input-border-color: map-get($grey, "lighten-1");
$input-focus-color: $primary-color;
$input-font-size: 1rem;
$form-control-margin: 0.5rem;
$form-control-border-color: $primary-color;
// TODO
$input-disabled-color: rgba(0,0,0, .46) !default;
$input-disabled-solid-color: $primary-color !default;

// Radio Buttons
$radio-fill-color: $primary-color !default;
$radio-empty-color: #5a5a5a !default; 

// Dropdown
$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-color: $primary-color !default;
$dropdown-item-height: 40px !default;

// Inputs
$input-bg-color: #fff !default;
$input-error-color: $error-color !default;
$input-success-color: $success-color !default;
$input-focus-color: $primary-color !default;
$label-font-size: .8rem !default;
$input-disabled-color: rgba(0,0,0, .46) !default;
// $input-disabled-solid-color: $primary-color !default; //trochę dziwny kolor był tutaj wpisany dlatego zamieniłem
$input-disabled-solid-color: #BDBDBD;

// Table
$product-table-img-max-height: 150px;
$product-table-img-min-width: 50px;
$table-inverse-color-border: $white !default;

// Footer
$footer-margin-top: 20px;
$footer-padding-top: 20px;
$footer-copyright-height: 50px;
$footer-copyright-line-height: 50px;
$footer-call-to-action-pt: 1.3rem;
$footer-call-to-action-pb: 0.5rem;
$footer-call-to-action-pr: 10px;
$footer-insta-photos-max-width: 100px;
$footer-insta-photos-margin: 4px;

// Images Path
$image-path: '../../../img/';

// Reponsive Headings
$responsive-headings: (
  "xs": (
    "h1": 150%,
    "h2": 145%,
    "h3": 135%,
    "h4": 135%,
    "h5": 135%
  ),
  "sm": (
    "h1": 170%,
    "h2": 140%,
    "h3": 125%,
    "h4": 125%,
    "h5": 125%
  ),
  "md": (
    "h1": 200%,
    "h2": 170%,
    "h3": 140%,
    "h4": 125%,
    "h5": 125%
  ),
  "lg": (
    "h1": 200%,
    "h2": 170%,
    "h3": 140%,
    "h4": 125%,
    "h5": 125%
  ),
  "xl": (
    "h1": 250%,
    "h2": 200%,
    "h3": 170%,
    "h4": 140%,
    "h5": 125%
  ),
);

/*** Global ***/
// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$sidenav-breakpoint: 1360px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;
$hide-sidenav: "only screen and (max-width : #{$sidenav-breakpoint})" !default;

// Link color
$link-color: #0275d8;

$navbar-option: (
  "light": (
    navbar-toggler-icon: $navbar-light-toggler-icon,
    navbar-option-color: $black,
    navbar-link-hover-color: $navbar-light-hover-color,
    navbar-active-link-bg-color: $navbar-light-bg-active-color,
    navbar-placeholder-color: $black
  ),
  "dark": (
    navbar-toggler-icon: $navbar-dark-toggler-icon,
    navbar-option-color: $white,
    navbar-link-hover-color: $navbar-dark-hover-color,
    navbar-active-link-bg-color: $navbar-dark-bg-active-color,
    navbar-placeholder-color: $white
  )
);