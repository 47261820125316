// Sidenav background variants
.sn-bg-1 {
  background-image: url(https://mdbootstrap.com/img/Photos/Others/sidenav1.jpg);
}

.sn-bg-2 {
  background-image: url(https://mdbootstrap.com/img/Photos/Others/sidenav2.jpg);
}

.sn-bg-3 {
  background-image: url(https://mdbootstrap.com/img/Photos/Others/sidenav3.jpg);
}

.sn-bg-4 {
  background-image: url(https://mdbootstrap.com/img/Photos/Others/sidenav4.jpg);
}

@each $skin, $data in $skins {
  .#{$skin}-skin {
    .gradient {
      background: map-get($data, skin-gradient-start);
      /* Old browsers */
      background: -moz-linear-gradient(-45deg, map-get($data, skin-gradient-start) 0%, map-get($data, skin-gradient-end) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg, map-get($data, skin-gradient-start) 0%, map-get($data, skin-gradient-end) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, map-get($data, skin-gradient-start) 0%, map-get($data, skin-gradient-end) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .primary-color {
      background-color: map-get($data, skin-primary-color)!important;
    }
    .navbar {
      background-color: map-get($data, skin-navbar);
      color: map-get($data, skin-text);
      .navbar-nav {
        .nav-item {
          .dropdown-menu a {
            &:hover,
            &:focus,
            &:active {
              background-color: darken( map-get($data, skin-accent), 5%);
            }
          }
          @if $skin == "white" {
            a {
              color: map-get($data, skin-text);
            }
          }
        }
      }
      &.double-nav {
        @if $skin == "white" {
          a {
            color: map-get($data, skin-text);
          }
        }
      }
    }
    .page-footer {
      background-color: map-get($data, skin-footer-color);
    }
    .side-nav {
      background-color: map-get($data, skin-flat);
      .logo-wrapper {
        &> div {
          background-color: transparent!important;
        }
      }
      .sn-avatar-wrapper img {
        border: 3px solid darken( map-get($data, skin-accent), 15%);
      }
      .social a {
        @if $skin == "white" {
          .fa {
            color: map-get($data, skin-text);
          }
        }
        &:hover {
          .fa {
            color: map-get($data, skin-accent) !important;
            @include transition (all 0.3s linear);
          }
        }
      }
      @if $skin == "white" {
        .search-form input[type=text] {
          color: #424242 !important;
          border-bottom: 1px solid rgba(153, 153, 153, 0.3);
          @include placeholder {
            color: rgba(map-get($data, skin-text), .5) !important;
          }
        }
      }
      .collapsible {
        li {
          background-color: transparent;
          @if $skin == "white" {
            a {
              font-weight: 400;
            }
          }
          .collapsible-header {
            color: map-get($data, skin-text);
            @include transition (all 0.3s linear);
            &.active {
              @if $skin == "white" {
                color: map-get($data, skin-sidenav-item);
                background-color: transparent;
              } @else {
                background-color: map-get($data, skin-sidenav-item-hover);
              }
            }
            &:hover {
              background-color: map-get($data, skin-sidenav-item-hover);
            }
          }
          .collapsible-body a {
            color: map-get($data, skin-text);
            &:hover {
              color:map-get($data, skin-sn-child);
            }
          }
        }
        @if $skin == "white" {
          a {
            color: map-get($data, skin-text);
            @include transition (all 0.3s linear);
            &:hover {
              background-color: map-get($data, skin-sidenav-item-hover);
            }
          }
        }
      }
      .fa {
        color: map-get($data, skin-text);
      }
      .sidenav-bg {
        &:after,
        &.mask-strong:after,
	            {
          background: map-get($data, skin-mask-strong);
        }
        &.mask-light:after {
          background: map-get($data, skin-mask-light);
        }
        &.mask-slight:after {
          background: map-get($data, skin-mask-slight);
        }
      }
    }
    @include make-button('primary', map-get($data, skin-btn-primary));
    @include make-button('secondary', map-get($data, skin-btn-secondary));
    @include make-button('default', map-get($data, skin-btn-default));
    .card .btn-action {
      background: map-get($data, skin-btn-default);
      &:hover,
      &:focus {
        background-color: lighten( map-get($data, skin-btn-default), 5%)!important;
      }
      &.active {
        background-color: darken( map-get($data, skin-btn-default), 20%)!important;
      }
    }
    // Custom inputs
    input[type="email"]:focus:not([readonly]),
    input[type="text"]:focus:not([readonly]),
    input[type="password"]:focus:not([readonly]),
    textarea.md-textarea:focus:not([readonly]) {
      border-color: map-get($data, skin-accent);
      box-shadow: 0 1px 0 0 map-get($data, skin-accent);
      &+label {
        color: map-get($data, skin-accent);
      }
    }
    input[type=checkbox]:checked {
      &+label {
        &:before {
          border-right: 2px solid map-get($data, skin-accent);
          border-bottom: 2px solid map-get($data, skin-accent);
        }
      }
    }
    input[type=checkbox].filled-in:checked {
      &+label {
        &:before {
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
        }
        &:after {
          background-color: map-get($data, skin-accent);
          border-color: map-get($data, skin-accent);
        }
      }
    }
    .md-form {
      .prefix {
        &.active {
          color: map-get($data, skin-accent);
        }
      }
    }
    /* Select colors */
    .dropdown-content {
      li:not(.disabled) {
        span {
          color: map-get($data, skin-accent);
        }
      }
    }
    .top-nav-collapse {
      background-color: map-get($data, skin-navbar);
    }
    .carousel-multi-item {
      .controls-top > a,
      .carousel-indicators li,
      .carousel-indicators li.active {
        background-color: map-get($data, skin-accent);
      }
    }
    // Form-header, card-header
    .form-header,
    .card-header {
      background-color: lighten( map-get($data, skin-accent), 2%);
    }
    .spinner-primary-color,
    .spinner-primary-color-only {
      border-color: map-get($data, skin-primary-color);
    }
    .pagination-primary-color {
      .page-item.active .page-link,
      .page-item.active .page-link:focus,
      .page-item.active .page-link:hover {
        color: #fff;
        background-color: map-get($data, skin-primary-color);
        border-color: map-get($data, skin-primary-color);
      }
      .page-link {
        color: map-get($data, skin-primary-color);
      }
    }
  }
}