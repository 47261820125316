// Forms basic
// Text inputs
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=date],
input[type=datetime-local],
input[type=tel],
input[type=number],
input[type=search-md],
input[type=search],
textarea.md-textarea {
  // General Styles
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $input-border-color;
  border-radius: 0;
  outline: none;
  height: 2.1rem;
  width: 100%;
  font-size: $input-font-size;
  box-shadow: none;
  box-sizing: content-box;
  @include transition(all .3s);

  // Disabled & readonly
  &:disabled, &[readonly="readonly"] {
    color: $input-disabled-color;
    border-bottom: 1px dotted $input-disabled-color;
    background-color: transparent;
    + label {
      color: $input-disabled-color;
      background-color: transparent;
    }
  }
  // Focused input style
  &:focus:not([readonly]) {
    border-bottom: 1px solid $input-focus-color;
    box-shadow: 0 1px 0 0 $input-focus-color;
    // Focused label style
    + label {
      color: $input-focus-color;
    }
  }

  // Valid input style
  &.valid, &:focus.valid {
    border-bottom: 1px solid $input-success-color;
    box-shadow: 0 1px 0 0 $input-success-color;
  }

  &.valid + label:after, &:focus.valid + label:after {
    content: attr(data-success);
    color: $input-success-color;
    opacity: 1;
  }
  // Invalid input style
  &.invalid, &:focus.invalid {
    border-bottom: 1px solid $input-error-color;
    box-shadow: 0 1px 0 0 $input-error-color;
  }
  &.invalid + label:after, &:focus.invalid + label:after {
    content: attr(data-error);
    color: $input-error-color;
    opacity: 1;
  }
  // Form message shared styles
  + label:after {
    display: block;
    content: "";
    position: absolute;
    top: 65px;
    opacity: 0;
    transition: .2s opacity ease-out, .2s color ease-out;
  }

  &.input-alternate {
    padding: 0 15px;
    height: 2.1rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
    font-size: 0.875rem;
    border-bottom: 0;
    transition: none !important;
    &:hover,
    &:focus {
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .2), 0 0 0 1px rgba(0, 0, 0, .08) !important;
      border-bottom: 0;
    }
  }
}

// Input with label
.form-control {
  padding: 0;
  padding-bottom: 0.6rem;
  padding-top: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: transparent;
  background-image: none;
  border-radius: 0;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  &:focus {
    background: transparent;
    box-shadow: none;
  }
  &:disabled,
  &[readonly] {
    background-color: transparent;
    border-bottom: 1px solid #bdbdbd;
  }
}
// Input + label wrapper styles
.md-form {
  position: relative;
  margin-bottom: 1.5rem;
  label {
    @include transition(.2s ease-out);
    color: #757575;
    position: absolute;
    top: 0.8rem;
    left: 0;
    font-size: 1rem;
    cursor: text;
    &.active {
      @include transform(translateY(-140%));
      font-size: $label-font-size;
    }
  }
  // Icon
  .prefix {
    @include transition(color .2s);
    position: absolute;
    width: 3rem;
    font-size: 2rem;
    padding-top: .5rem;
    ~ input, ~ textarea {
      margin-left: 3rem;
      width: 92%;
      width: calc(100% - 3rem);
    }
    ~ label {
      margin-left: 3rem;
    }
    &.active {
      color: $input-focus-color;
    } 
  }

  @media #{$medium-and-down} {
    .prefix ~ input {
      width: 86%;
      width: calc(100% - 3rem);
    }
  }
  @media #{$small-and-down} {
    .prefix ~ input {
      width: 80%;
      width: calc(100% - 3rem);
    }
  }
  .btn {
    margin-bottom: 1.5rem;
  }

  &.form-sm {
    input {
      padding-bottom: 0.2rem;
      padding-top: 0.2rem;
      font-size: 0.8rem;
      line-height: 0.5;
    }
    label {
      font-size: 0.9rem;
    }
    .prefix {
      font-size: 1.5rem;
      top: 0.4rem;
    }
    .prefix ~ input, .prefix ~ textarea, .prefix ~ label  {
      margin-left: 2.2rem;
    }
  }

  &.input-group {
    padding-left: 1rem;
    @include placeholder {
      color: #999;
      padding-top: 2px;
    }
  }
}

.form-group {
  display:block;
}

.form-inline {
  fieldset {
    margin-right: 1.5rem;
  }
  .form-group {
    margin-right: 2rem;
  }
}   
// Default textarea
textarea {
  width: 100%;
  height: 3rem;
  background-color: transparent;
  &.md-textarea {
    overflow-y: hidden;
    padding: 1.6rem 0;
    resize: none;
    min-height: 3rem;
  }
}

// For textarea autoresize
.hiddendiv {
  display: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding-top: 1.2rem;
}

.orange-gradient{
  background: linear-gradient(40deg, #FFD86F, #FC6262);
}