.form-header {
  color: #fff;
  text-align: center;
  margin-top: -50px;
  margin-bottom: 3rem;
  padding: 1rem;
  @extend .z-depth-1-half;
  @include border-radius(2px);
}

.card .card-body h3 {
  margin-bottom: 0;
  padding: 0.7rem;
}

.md-form {
  .prefix.grey-text {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
}


.section .contact-icons {
  text-align: center;
  list-style-type:none;
  padding:0;
  li {
    margin-bottom: 2.2rem;
  }
}

section{ 
  .form{
    form{
      .btn-floating{
        float: right;
        position: relative;
        bottom: 3rem;
        margin-right: 0;
      }
    }
  }
  .contact{
    background-color: #103f6d;
    height: 100%;
    @extend .white-text;
    .contact-icons{
      li{
        i{
          float: left;
          clear: both;
          margin-right: 1rem;
          &.fa{
            font-size: 1.5rem;
          }
        }
        p{
          padding-top: .5rem;
          text-align: left;
        }
      }
    }
    i{
      color: #6b89a5;
    }
  }
}