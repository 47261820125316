// Miscellaneous free

// White border
.table-inverse {
  th,
  td,
  thead th {
    border-color: $table-inverse-color-border;
  }
}

// Edge Headers
.edge-header {
  display:block;
  height:278px;
  background-color:#ccc;
}

.free-bird {
  margin-top:-100px;
}