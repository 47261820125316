// Rotating card
.card-wrapper {
    height: 500px;
    position: relative;
    perspective: 800px;
    .face {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        h4 {
            margin-bottom: 15px;
        }
        h5 {
            margin-top: 30px;
        }
        .content {
            text-align: left;
            padding: 15px;
            p {
                margin-bottom: 30px;
            }
            .rotate-btn {
                padding: 1rem;
                margin-right: -8px;
                float: right;
                font-size: 1.2rem;
                color: #000;
            }
        }
    }
    /* Front side */
    .card-up {
        overflow: hidden;
        height: 50%;
    }
    .card-up img {
        min-width: 400px;
        width: 100%;
    }
    .avatar {
        border-radius: 50%;
        display: block;
        height: 120px;
        margin: -65px auto 0;
        overflow: hidden;
        width: 120px;
    }
    .avatar img {
        border: 5px solid #fff;
        background: none repeat scroll 0 0 #FFFFFF;
        width: 100%;
    }
    /* Card with rotate on click */
    .card-rotating {
        text-align: center;
        position: absolute;
        width: 100%;
        height: 100%;
        .content {
            position:relative;
            .rotate-btn {
                position:absolute;
                right:8px;
                top:0;
            }
        }
    }
    /* Rotate icons */
    .fa-repeat,
    .fa-undo {
        font-size: 20px;
        margin-top: 30px;
    }
    .fa-undo {
        margin-top: 30px;
    }
    /* Animation */
    .card-rotating {
        transform-style: preserve-3d;
    }
    .front,
    .back {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transition: -webkit-transform 1.0s;
        transition: transform 1.0s;
        @extend .z-depth-1;
    }
    .fron {
        z-index: 2;
        cursor: auto;
    }
    .back {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
        padding: 1rem;
        .card-title {
            cursor: pointer;
            i {
                color: #9e9e9e;
                position: absolute;
                right: 20px;
            }
        }
    }
    /* Click effect */
    .card-rotating.effect__click.flipped .front {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
    .card-rotating.effect__click.flipped .back {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
}


// Fix to remove white line in YouTube player
.embed-responsive-item {
    background: #000;
}


.tp-box {
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s;
  .tp-box_side {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    position: absolute;
    text-align: center;
    /*cursor: pointer;*/
  }
  .tp-box_back {
    transform: rotateY(179.9deg);
  }
}

.flip-container {
    perspective: 1000px;
    transform-style: preserve-3d;

    .flipper {
        transition: 1s;
        transform-style: preserve-3d;
        position: relative;
    }

    .front {
        z-index: 2;
        transform: rotateY(0deg);
    }

    .back {
        transform: rotateY(-180deg);
    }

    .front, .back {
        backface-visibility: hidden;
        transition: 1s;
        transform-style: preserve-3d;
        position: absolute;
        top: 0;
        left: 0;
    }

    &.rotate .back {
        transform: rotateY(0deg);
        background-color: #fff;
    }

    &.rotate .front {
        transform: rotateY(180deg);
    }
}






