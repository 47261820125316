doublenavbar {
    .side-nav, #sidenav-overlay {
        transition: 0.2s;
    }
}


doublenavbar {
    links {
        display: flex;
    }
}