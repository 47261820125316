ng2-completer {
  .completer-dropdown-holder {
    margin-top: -1rem;
  }
  .md-form {
    label {
      z-index: -1;
    }
  }
}


.completer-dropdown {
  margin-top: 1px;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,.25);
  z-index: 100;
  max-height: 210px;
  overflow-y: auto;
  overflow-x: hidden;
  .completer-row {
    width: 100%;
    padding: 12px 15px;
    font-size: .875rem;
    .completer-description {
      font-size: 14px;
    }
    .completer-image-holder {
      float: left;
      width: 10%;
        .completer-image-default {
          width: 16px; 
          height: 16px;
      }
    }
    .completer-item-text-image {
      float: right;
      width: 90%;
    }
  }
  .completer-no-results,
  .completer-searching {
    padding: 12px 15px;
    font-size: .875rem;
  }  
}

.completer-selected-row {
  background-color: #eee;
}