// Charts Pro

.min-chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  } 
  .percent {
    display: inline-block;
    line-height: 110px;
    z-index: 2;
    &:after {
      content: '%';
      margin-left: 0.1em;
      font-size: .8em;
    }
  }
}