//Badges
.badge {
  color: #fff;
}

@each $name, $color in $material-colors {
  .badge-#{$name} {
    background-color: $color;
  }
}

.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
}

.badge-warning {
  color: #FFF !important;
}