// Buttons
button:focus {
  outline:0 !important;
}
.btn {
  font-size: 0.8rem;
  padding: 0.85rem 2.13rem;
  margin: 6px;
  border-radius: 2px;
  border: 0;
  transition: .2s ease-out;
  text-transform: uppercase;
  white-space: normal !important;
  word-wrap: break-word;
  cursor: pointer;
  @extend .white-text;
  @extend .z-depth-1;
  &:hover,
  &:active,
  &:focus {
    @extend .z-depth-1-half;
    outline: 0;
  }
  .fa {
    font-size: 1rem;
    position: relative;
    vertical-align: middle;
    margin-top: -2px;
    &.right {
      margin-left: 3px;
    }
    &.left {
      margin-right: 3px;
    }
  }
  &.btn-lg {
    font-size: 0.9rem;
    padding: 1rem 2.4rem;
    .fa {
      font-size: 1.2rem;
    }
  }
  &.btn-md {
    font-size: 0.7rem;
    padding: 0.7rem 1.6rem;
    .fa {
      font-size: 0.9rem;
    }
  }
  &.btn-sm {
    font-size: 0.6rem;
    padding:  0.5rem 1.6rem;
    &.btn-table {
      padding: 0.5rem 0.9rem;
    }
    .fa {
      font-size: 0.7rem;
    }
  }
  &.btn-tb {
    padding: 0.3rem 1rem;
  }
  &.disabled,
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
  &[class*="btn-outline-"] {
    padding-top:.76rem;
    padding-bottom: .76rem;
    &.btn-sm {
      padding-top:.42rem;
      padding-bottom: .42rem;
    }
    &.btn-md {
      padding-top:.58rem;
      padding-bottom: .58rem;
    }
    &.btn-lg {
      padding-top:.9rem;
      padding-bottom: .9rem;
    }
  }
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #b579d2!important;
  border-color: #b579d2 !important; 
}

.btn-group {
  .btn {
    margin: 0;
  }
}
.btn-block {
  margin: inherit;
}
.btn-split {
  padding-left: 0.85rem;
  padding-right: 1.25rem;
}
.btn-link {
  background-color: transparent;
  @extend .black-text;
  box-shadow: none;
  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }
}
.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  @extend .z-depth-1;
}
@each $btn-name, $color-value in $material-colors {
  @include make-button($btn-name, $color-value);
  @include make-outline-button($btn-name, $color-value);
}
