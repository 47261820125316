// Navbar
.navbar {
  font-weight: $font-weight;
  padding-right: 1rem !important;
  form {
    input {
      margin: 0 $navbar-form-input-mr $navbar-form-input-mb $navbar-form-input-ml;
      height: $navbar-form-input-height;
    }
  }
  .navbar-brand {
    align-self: flex-start;
    overflow: visible;
  }
  .breadcrumb {
    margin: 0;
    background-color: inherit;
    font-weight: $font-weight;
    // padding-left: 1rem;
    font-size: $navbar-double-font-size;
    padding: 0.3em 0 0 1em;
  }
  .navbar-toggler {
    border-width: 0;
  }
  &.double-nav,
  .nav-flex-icons {
    flex-direction: row;
  }
  .container {
    @media (max-width: $medium-screen) {
      width: 100%;
      .navbar-toggler-right {
        right: 0;
      }
    }
  }
  &.navbar-dark {
    .navbar-nav {
      .nav-item {
        .nav-link.disabled, .nav-link.disabled:hover {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
  &.navbar-light {
    .navbar-nav {
      .nav-item {
        .nav-link.disabled, .nav-link.disabled:hover {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  .nav-item {
    .nav-link {
      display:block;
      &.disabled {
        &:active {
          pointer-events:none;
        }
      }
      .fa {
        padding-left: $navbar-flex-icons-padding-lg;
        padding-right: $navbar-flex-icons-padding-lg;
      }
      @media (max-width: $medium-screen) {
        padding-left: $navbar-flex-icons-padding-md;
        padding-right: $navbar-flex-icons-padding-md;
      }
    }
  }
  .dropdown-menu {
    position: absolute !important;
    margin-top:0;
    a {
      font-size: $navbar-dropdown-font-size;
      font-weight: $font-weight;
      padding: $navbar-dropdown-menu-padding;
      color: $black !important;
      &:hover {
        color: $white !important;
      }
    }
    &.dropdown-menu-right {
      @media (max-width: $small-screen) {
        right: auto !important;
      }
    }
  }
  @each $name, $data in $navbar-option {
    &.navbar-#{$name} {
      .navbar-toggler-icon {
        background-image: map-get($data, "navbar-toggler-icon");
        cursor: pointer;
      }
      .breadcrumb,
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: map-get($data, "navbar-option-color");
            @include transition(.35s);
            &:hover {
              color: map-get($data, "navbar-link-hover-color");
            }
          }
          &.active > .nav-link {
            background-color: map-get($data, "navbar-active-link-bg-color");
            &:hover {
              color: map-get($data, "navbar-option-color");
            }
          }
        }
      }
      .navbar-toggler {
        color: map-get($data, "navbar-option-color");
      }
      form {
        input[type=text] {
          border-bottom: 1px solid map-get($data, "navbar-option-color");
          &:focus:not([readonly]) {
            border-color: $input-focus-color;
          }
        }
        .form-control {
          color: map-get($data, "navbar-option-color");
          @include placeholder {
            color: map-get($data, "navbar-option-color");
            font-weight: $font-weight;
          }
        }
      }
    }
  }
  &.scrolling-navbar {
    z-index: 100;
    @media (min-width: $small-screen) {
      @include transition (background .5s ease-in-out, padding .5s ease-in-out);
      padding-top: $navbar-scrolling-padding;
      padding-bottom: $navbar-scrolling-padding;
      .navbar-nav > li {
        transition-duration: 1s;
      }
      &.top-nav-collapse {
        padding-top: $navbar-top-collapse-padding;
        padding-bottom: $navbar-top-collapse-padding;
      }
    }
  }
}

.intro-margin {
  @media (min-width: $small-screen) {
    &.view {
      overflow: visible;
      margin-top: -56px;
    }
  }
}