// FOOTER
footer {
  &.page-footer {
    margin-top: $footer-margin-top;
    padding-top: $footer-padding-top;
    color: $white;
    .container-fluid {
      width:auto;
    }
    .footer-copyright {
      overflow: hidden;
      height: $footer-copyright-height;
      line-height: $footer-copyright-line-height;
      color: rgba($white, .6);
      background-color: rgba($black, .2);
      text-align: center;
      font-size: 0.9rem;
    }
    a {
      color: $white;
    }
    .title {
      text-transform: uppercase;
    }
    .call-to-action {
      text-align: center;
      padding-top: $footer-call-to-action-pt;
      padding-bottom: $footer-call-to-action-pb;
      ul li {
        display: inline-block;
        padding-right: $footer-call-to-action-pr;
      }
    }
    .social-section {
      ul li  {
        display: inline-block;
      }
      ul {
        a {
          margin-left: 0;
          padding-left: 0;
        }
      }
    }
    ul {
      list-style-type:none;
      padding:0;
    }
  }
}

/* Instagram photos */ 
ul.instagram-photos {
  list-style-type: none;
  padding:0;
  li {
    display: inline-block;
    max-width: $footer-insta-photos-max-width;
    margin: $footer-insta-photos-margin;
    img {
      margin: 0;
      @extend .z-depth-1-half;
    }
  }
}