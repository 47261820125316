@media (min-width: 1200px) {
	.offset-xl-1 {
	    margin-left: 8.333333%;
	}
}

DoubleNavbar{
	header {
		height: 0 !important;
	}
}