// Testimonials
.testimonial-carousel {
  .carousel-control {
    background-image: none;
    top:50%;
    transform: translateY(-35%);
    &:before {
      font-size: 5rem;
      color: rgba($black,.6);
    }
    &:hover {
      &:before {
        color: $black;
        @include transition(0.4s);
      }
    }
    &.left {
      left:6%;
      &:before {
        content: "\2039";
      }
    }
    &.right {
      right:6%;
      &:before {
        content: "\203a";
      }
    }
  }
  .testimonial {
    margin-right: 15%;
    margin-left: 15%;
    .avatar {
      margin-bottom: 2rem;
      @extend .flex-center;
      img {
        max-width: 170px;
        @extend .z-depth-2;
      }
    }
    h4 {
      margin-bottom: 1.3rem;
      font-weight: 500;
    }
    h5 {
      margin-bottom: 0.9rem;
      color: map-get($grey, "darken-3");
      font-weight: 400;
    }
    p {
      color: #666;
    }
  }
}