// Scroll bar

.ps {
  touch-action: auto;
  overflow: hidden !important;
  &.ps--active-x > .ps__scrollbar-x-rail,
  &.ps--active-y > .ps__scrollbar-y-rail {
    display: block;
    background-color: transparent; 
  }
  &.ps--active-y > .ps__scrollbar-y-rail {
    top:0 !important;
  }
  &.ps--in-scrolling.ps--x {
    > .ps__scrollbar-x-rail {
      background-color: #eee;
      opacity: 0.9; 
      > .ps__scrollbar-x {
        background-color: #999;
        height: 11px; 
      }
      
    }
  }
  > .ps__scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 0px;
    /* there must be 'bottom' for ps__scrollbar-x-rail */
    height: 15px; 
    > .ps__scrollbar-x {
      position: absolute;
      /* please don't change 'position' */
      background-color: #aaa;
      border-radius: 6px;
      transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
      bottom: 2px;
      /* there must be 'bottom' for ps__scrollbar-x */
      height: 6px;

    }
    &:hover > .ps__scrollbar-x,
    > .ps__scrollbar-x-rail:active > .ps__scrollbar-x {
      height: 11px; 
    }
  }
  &.ps--in-scrolling.ps--y {
    > .ps__scrollbar-y-rail {
      background-color: #eee;
      opacity: 0.9; 
      > .ps__scrollbar-y {
        background-color: #999;
        width: 11px; 
      }
    }
  }
  > .ps__scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 0;
    /* there must be 'right' for ps__scrollbar-y-rail */
    width: 15px; 
    z-index: 999;
    > .ps__scrollbar-y {
      position: absolute;
      /* please don't change 'position' */
      background-color: #aaa;
      border-radius: 6px;
      transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
      right: 2px;
      /* there must be 'right' for ps__scrollbar-y */
      width: 6px; 
    }
    &:hover > .ps__scrollbar-y,
    > .ps__scrollbar-y-rail:active > .ps__scrollbar-y {
      width: 11px; 
    }
  }
  &:hover.ps--in-scrolling.ps--x {
    > .ps__scrollbar-x-rail {
      background-color: #eee;
      opacity: 0.9; 
      > .ps__scrollbar-x {
        background-color: #999;
        height: 11px; 
      }
    }
  }
  &:hover.ps--in-scrolling.ps--y {
    > .ps__scrollbar-y-rail {
      background-color: #eee;
      opacity: 0.9; 
      > .ps__scrollbar-y {
        background-color: #999;
        width: 11px; 
      }
    }
  }
  &:hover > .ps__scrollbar-x-rail,
  &:hover > .ps__scrollbar-y-rail {
    opacity: 0.6; 
  }
  &:hover {
    > .ps__scrollbar-x-rail{
      &:hover {
        background-color: #eee;
        opacity: 0.9; 
        > .ps__scrollbar-x {
          background-color: #999; 
        }
      }
    }
  }

  &:hover {
    > .ps__scrollbar-y-rail {
      &:hover {
        background-color: #eee;
        opacity: 0.9; 
        > .ps__scrollbar-y {
          background-color: #999; 
        }
      }
    }
  }

}