body {
  padding-top: 50px;
  padding-bottom: 20px;
}

/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

/* Carousel */
.carousel-caption p {
  font-size: 20px;
  line-height: 1.4;
}

/* Make .svg files in the carousel display properly in older browsers */
.carousel-inner .item img[src$=".svg"] {
  width: 100%;
}

/* QR code generator */
#qrCode {
  margin: 15px;
}

/* sitewide link styling */
a {
  color: #d23138;
}

  a:hover {
    color: #d23138;
    text-decoration: underline;
  }


/* Hide/rearrange for smaller screens */
@media screen and (max-width: 767px) {
  /* Hide captions */
  .carousel-caption {
    display: none;
  }
}
.classic-tabs.compactView li.nav-item a {
  padding: 10px 10px !important;
}

.classic-tabs.compactView li.nav-item:first-child {
  margin-left: 0px !important;
}
.compactView a h5
{
  font-size: 0.9rem!important;
}
input.cbxOptOut {
  position: relative !important;
  left: auto !important;
  visibility: visible !important;
  transform: scale(1.5);
}



/*Table Generic Classes*/

.row-toggler{
  cursor: pointer;
  text-align: left;
}

.row-toggler i{
  font-size: 1rem;
}

.row-toggler:hover i{
  color: #36496d;
}

.modal-header {
  justify-content: center;
}