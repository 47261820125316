// Cards Pro
.card {
  // Common styles for cards pro
  &.card-image {
    background-size: cover;
    background-position: center;
    width: 100%;
  }
  .card-data {
    background-color: map-get($mdb-color, 'lighten-3');
    color: #fff;
    text-align: center;
    height: auto;
    padding: 10px;
    ul {
      margin-bottom: 0;
      font-size: 0.9rem;
      li {
        display: inline;
        .fa-clock-o {
          margin-left: 10px;
        }
      }
      a {
        color: #fff;
        .fa {
          margin-left: 5px;
          margin-right: 3px;
        }
      }
    }
  }
  &.card-dark {
    .activator {
      @extend .white-text;
    }
    .card-body {
      background-color: $elegant-color;
      @extend .white-text;
      hr {
        background-color: #666;
      }
      a {
        @extend .white-text;
      }
    }
  } // Cascading cards
  &.card-cascade {
    width: 100%;
    .view {
      @extend .z-depth-2;
      border-radius: 4px;
      &.gradient-card-header {
        padding: 1.6rem 1rem;
        text-align: center;
        @extend .white-text;
        h2 {
          font-weight: 400;
        }
        .btn-floating {
          background-color: rgba(255, 255, 255, .2);
        }
      }
    }
    &.narrower {
      margin-top: 20px;
      .view {
        margin-left: 4%;
        margin-right: 4%;
        margin-top: -20px;
      }
    }
    &.wider {
      background-color: transparent;
      @extend .z-depth-0;
      .view {
        z-index: 2;
      }
      .card-body {
        margin-left: 4%;
        margin-right: 4%;
        @extend .z-depth-1;
        background: #fff;
        z-index: 1;
        border-radius: 0 0 0.3rem 0.3rem;
      }
      &.reverse {
        .view {
          //@extend .z-depth-1;
        }
        .card-body {
          z-index: 3;
          margin-top: -1rem;
          border-radius: 4px;
          @extend .z-depth-1-half;
        }
      }
    }
    &.card-avatar {
      margin-top: 6rem;
      text-align: center;
      img {
        margin-top: -5rem;
        margin-left: auto;
        margin-right: auto;
        width: 160px;
        border-radius: 50%;
        @extend .z-depth-1-half;
      }
    }
    .card-body {
      padding-top: 1.8rem;
    }
    &.panel-cascade {
      .view {
        text-align: center;
        color: #fff;
      }
      .list-group {
        .list-group-item {
          margin-bottom: 0px;
          border: 0;
          border-bottom: 1px solid #eee;
          color: #495057;
          &:hover { 
            background-color: #eee;
          }
        }
      }
    }
  } 
  // activator
  .activator {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    margin-right: 10px;
    font-size: 1.2rem;
    @extend .black-text;
  } // review card
  .rating {
    color: #ffa000;
  }
  .btn-action {
    margin: -23px 20px;
    margin-left: auto;
    background-color: map-get($mdb-color, 'lighten-3');
    height: 47px;
    width: 47px;
    line-height: 0;
    .fa {
      font-size: 18px;
      &.fa-chevron-right {
        margin-top: 2px;
        margin-left: 2px;
      }
    }
  }
  .card-review {
    margin-top: -1px;
    @extend .black;
    color: #fff;
    padding: 50px 20px 20px 20px;
    ul {
      margin-bottom: 0;
      font-size: 0.9rem;
      li {
        display: inline;
      }
      a {
        color: #fff;
        .fa {
          margin-left: 5px;
          margin-right: 3px;
        }
      }
    }
  } //Card Reveal
  .card-reveal {
    position: absolute;
    @extend .white;
    width: 100%;
    overflow-y: auto;
    top: 100%;
    height: 100%;
    z-index: 1;
    display: none;
    padding: 1rem;
    .card-title {
      cursor: pointer;
      i {
        color: #9e9e9e;
        position: absolute;
        right: 10px;
      }
    }
    .content {
      position: relative;
      h4 {
        margin-top: 20px;
        margin-bottom: 15px;
      }
      h5 {
        margin-top: 30px;
      }
    }
  } //Social reveal
  .card-share {
    position: relative;
    .social-reveal {
      position: absolute;
      top: -28px;
      right: 30px;
      visibility: hidden;
      width: auto;
      transform: translateZ(0);
      transform: translateX(0px);
      transition: transform 0.35s ease;
      .btn-floating {
        margin: 0.3rem;
        i {
          font-size: 18px;
        }
      }
    }
    .social-reveal-active {
      z-index: 11;
      visibility: visible;
      /*z-index: 3;*/
      transform: translateZ(0);
      transform: translateX(-48px);
      transition: transform 0.35s ease;
    }
  }
  &.ovf-hidden {
    overflow: hidden;
  }
  .card-footer {
    background-color: transparent; // padding: .75rem .5rem 0 .5rem; 
    .left {
      float: left;
      .discount {
        text-decoration: line-through;
        color: #9e9e9e;
        font-size: 1.1rem;
      }
    }
    .right {
      float: right;
      display: flex;
      a {
        color: #757575;
        margin-left: 1rem;
        @include transition (0.4s);
        &:hover {
          color: #d50000;
          @include transition (0.4s);
        }
        &.active {
          color: #d50000;
        }
      }
    }
  }
}

// Rotating card
.card-wrapper {
  margin: 0;
  min-height: 500px;
  position: relative;
  perspective: 800px;
  .face {
    position: absolute;
    width: 100%;
    height: 100%;
    @extend .white;
    h4 {
      margin-bottom: 15px;
    }
    h5 {
      margin-top: 30px;
    }
    .content {
      text-align: left;
      padding: 15px;
      p {
        margin-bottom: 30px;
      }
      .rotate-btn {
        padding: 1rem;
        margin-right: -8px;
        float: right;
        font-size: 1.2rem;
        @extend .black-text;
      }
    }
  }
  /* Front side */
  .card-up {
    overflow: hidden;
    height: 50%;
  }
  .card-up img {
    min-width: 400px;
  }
  .avatar {
    border-radius: 50%;
    display: block;
    height: 120px;
    margin: -65px auto 0;
    overflow: hidden;
    width: 120px;
  }
  .avatar img {
    border: 5px solid #fff;
    background: none repeat scroll 0 0 #FFFFFF;
    width: 100%;
  }
  /* Card with rotate on click */
  .card-rotating {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    .content {
      position: relative;
      .rotate-btn {
        position: absolute;
        right: 8px;
        top: 0;
      }
    }
  }
  /* Rotate icons */
  .fa-repeat,
  .fa-undo {
    font-size: 20px;
    margin-top: 30px;
  }
  .fa-undo {
    margin-top: 30px;
  }
  /* Animation */
  .card-rotating {
    transform-style: preserve-3d;
  }
  .front,
  .back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 1.0s;
    transition: transform 1.0s;
    @extend .z-depth-1;
  }
  .back {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    padding: 1rem;
    .card-title {
      cursor: pointer;
      i {
        color: #9e9e9e;
        position: absolute;
        right: 20px;
      }
    }
  }
  /* Click effect */
  .card-rotating.effect__click.flipped .front {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  .card-rotating.effect__click.flipped .back {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}

// Testimonial card
.testimonial-card {
  .card-up {
    overflow: hidden;
    height: 120px;
    border-radius: .3rem .3rem 0 0;
  }
  .avatar {
    @include border-radius(50%);
    height: 120px;
    width: 120px;
    margin: -65px auto 0;
    overflow: hidden;
    border: 5px solid #fff;
    @extend .white;
  }
  .avatar img {
    width: 100%;
  }
  .card-body {
    text-align: center;
  }
}

.card-inverse {
  h5 {
    font-size: 1rem;
  }
  h3 {
    font-weight: 400;
  }
}

// Card overlay
.card-overlay {
  background-size: cover;
  padding: 1.5rem;
  border-radius: 2px;
  @extend .z-depth-2;
  @extend .flex-center;
  p {
    margin-bottom: 1rem;
  }
}