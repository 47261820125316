.card-body{
  h3,
  h5 {
    font-weight: 400;
    margin-bottom: 1rem;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 1rem;
  }

  a {
    h5 {
      font-size: 1rem;
    }
  }
}