// SIDENAV
.side-nav {
  position: fixed;
  width: 240px;
  left: 0;
  top: 0;
  margin: 0;
  transform: translateX(-100%);
  height: 100%;
  height: calc(100% + 60px);
  height: -moz-calc(100%);
  padding-bottom: 60px;
  color:#d8d8d8;
  background-color: #2c2f34;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 999;
  backface-visibility: hidden;
  overflow-y: auto;
  will-change: transform;
  backface-visibility: hidden;
  transform: translateX(-105%);
  list-style-type:none;
  padding:0;
  @extend .white-text;
  @extend .z-depth-1;
  ul {
    list-style-type:none;
    li {
      padding:0;

    }
  }
  &.right-aligned {
    right: 0;
    left: auto;
    transform: translateX(100%);
  }
  .collapsible {
    margin: 0;
    padding:0;
    li {
      a{
        &:hover {
          background-color: rgba(0,0,0,.15);
        }
      }
    }
    >li {
      border-radius:2px;
      a.collapsible-header {
        &:hover {
          background-color: rgba(255, 255, 255, 0.15);
        }
        &.active {
          background-color: rgba(255, 255, 255, 0.15);
        }
      } 

    }
    ul {
      padding:0;
      list-style-type:none;
    }
    a {
      color:#fff;
      font-weight: 300;
      font-size: 0.8rem;
      height: 36px;
      line-height: 36px;
      &.active,
      &:hover {
        border-radius: 2px;
      }
    }
    .fa {
      font-size: 0.8rem;
      margin-right: 13px;
    }
  }
  .collapsible-body a {
    padding-left: 47px;
    height: 36px;
    line-height: 36px;
    background-color: rgba(0,0,0,.15);
  }
  a {
    display: block;
    font-size: 1rem;
    height: 56px;
    line-height: 56px;
    padding-left: 20px;
  }
  // SideNav logo
  .logo-wrapper {
    height: 140px;
    a {
      height: 140px;
      width: 240px;
      padding: 0;
    }
    img {
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 20%;
      padding-bottom: 20%;
      height: auto;
    }
  }
  @media (max-height: $medium-screen) {
    .logo-wrapper {
      height: 80px;
      a {
        height: 80px;
      }
      img {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 7%;
        padding-bottom: 7%;
      }
    }
  }
  // About
  .about {
    padding: 1rem;
    border-bottom: 1px solid rgba(153, 153, 153, 0.3);
    p {
      margin-bottom: 0;
      text-align: center;
    }
  }
  //Social
  .social {
    padding: 0;
    text-align: center;
    border-bottom: 1px solid rgba(153, 153, 153, 0.3);
    li {
      display: inline-block;
      padding: 0;
      margin: 0;
    }
    a {
      padding: 0;
      margin: 0;
      padding-right: 0.6rem;
      padding-left: 0.6rem;
    }
    .fa {
      color: #dbe4e7;
    }
  }
  //Search form
  .search-form {
    padding: 0;
    input[type=text] {
      border-bottom: 1px solid #fff;
      font-weight: 300;
      padding-left: 30px;
      @include placeholder {
        color: #fff!important;
      }
    }
    .form-control {
      @extend .white-text;
      margin-bottom: 0;
    }
  }
  .sn-avatar-wrapper {
    padding-left: 33%;
    padding-right: 33%;
    padding-top: 10%;
    img {
      margin: 0;
      padding: 0;
      max-width: 90px;
      border-radius: 5rem;
    }
  }
  @media only screen and (max-height: $medium-screen) {
    .sn-avatar-wrapper {
      padding-left: 40%;
      padding-right: 40%;
      padding-top: 3%;
      img {
        max-width: 50px;
      }
    }
  }
  .sn-ad-avatar-wrapper {
    height: auto;
    margin-bottom: 0;
    img {
      max-width: 60px;
      padding: 20px 10px;
      float: left;
    }
    p {
      font-size: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
      margin: 0;
    }
  }
  .user-box {
    padding: 20px 10px 0 10px;
    img {
      margin: 0 auto 10px auto;
      max-width: 80px;
    }
  }
  .fa-angle-down {
    &.rotate-icon {
      position: absolute;
      right: 0;
      top: 13px;
      margin-right: 20px;
    }
  }
  .sidenav-bg {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 240px;
    z-index: -1;
    background-attachment: fixed;
    &:after {
      width: 100%;
      display: block;
      content: "";
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      margin-bottom: -99999px;
      padding-bottom: 99999px;
    }
  }
  // Fixed side-nav shown
  &.fixed {
    left: 0;
    transform: translateX(0);
    position: fixed;
    // Right Align
    &.right-aligned {
      right: 0;
      left: auto;
    }
  }

  // Fixed sideNav hide on smaller
  @media #{$hide-sidenav} {
    &.fixed {
      transform: translateX(-105%);
      &.right-aligned {
        transform: translateX(105%);
      }
    }
  }
  &.side-nav-light {
    background-color:#e5e5e5;
    a {
      color:#555;
      font-weight:400;
    }
    .social .fa {
      color:#555;
    }
    .search-form input[type=text] {
      border-bottom-color:rgba(153, 153, 153, 0.3);
      color:#555 !important;
      @include placeholder {
        color:#555 !important;
      }
    }
    .collapsible-body a {
      background-color: rgba(0,0,0,.1);
    }
    .collapsible li .collapsible-header {
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &.active {
        color: #4285F4;
        background-color: transparent;
      }
    }
  }
}

// Touch interaction
.drag-target {
  height: 100%;
  width: 10px;
  position: fixed;
  top: 0;
  z-index: 998;
}

#sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(0, 0, 0, .5);
  z-index: 997;
  will-change: opacity;
}

//Button Collapse Without Navbar
.side-nav .double-navbar .bc-min {
  padding: 5px 10px;
  .button-collapse {
    padding-left: 0px;
  }
}