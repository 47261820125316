/* You can add global styles to this file, and also import other style files */

// Distance
$modal-distance:10px;
$modal-info-color: #5394ff;
$modal-success-color: #01d36b;
$modal-warning-color: #ff8e38;
$modal-danger-color: #ff4b4b;

// Styles for body
body {
    &.modal-open {
        padding-right: 0 !important;
    }
    &.scrollable {
        overflow-y: auto;
    }
}

// *** ENHANCED BOOTSTRAP MODALS ***///
// General styles
.modal-dialog {
    .modal-content {
        // @include border-radius(2px);
        // @extend .z-depth-1-half;
        border: 0;
    }
}

// Position & Size
.modal {
	padding-right:0 !important;
	.modal-dialog {
        @media (min-width: 768px) {
            &.modal-top {
                top: 0;
            }
            &.modal-left {
                left: 0;
            }
            &.modal-right {
                right: 0;
            }
            &.modal-bottom {
                bottom: 0;
            }
            &.modal-top-left {
                top: $modal-distance;
                left: $modal-distance;
            }
            &.modal-top-right {
                top: $modal-distance;
                right: $modal-distance;
            }
            &.modal-bottom-left {
                left: $modal-distance;
                bottom: $modal-distance;
            }
            &.modal-bottom-right {
                right: $modal-distance;
                bottom: $modal-distance;
            }
        }
    }
    .modal-side {
        &.modal-top {
            top: 0;
        }
        &.modal-left {
            left: 0;
        }
        &.modal-right {
            right: 0;
        }
        &.modal-bottom {
            bottom: 0;
        }
        &.modal-top-left {
            top: $modal-distance;
            left: $modal-distance;
        }
        &.modal-top-right {
            top: $modal-distance;
            right: $modal-distance;
        }
        &.modal-bottom-left {
            left: $modal-distance;
            bottom: $modal-distance;
        }
        &.modal-bottom-right {
            right: $modal-distance;
            bottom: $modal-distance;
        }
    }
    &.fade {
        &.top:not(.show) .modal-dialog {
            transform: translate3d(0, -25%, 0);
        }
        &.left:not(.show) .modal-dialog {
            transform: translate3d(-25%, 0, 0);
        }
        &.right:not(.show) .modal-dialog {
            transform: translate3d(25%, 0, 0);
        }
        &.bottom:not(.show) .modal-dialog {
            transform: translate3d(0, 25%, 0);
        }
        &.in {
            opacity: 1;
            .modal-dialog {
                // -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
                .relative {
                    display: inline-block;
                    // transform: translate3d(0, 0, 0);
                }
            }
        }
    }
    &.modal-scrolling {
        position: relative;
        .modal-dialog {
            position: fixed;
            z-index: 1050;
        }
    }
    &.modal-content-clickable {
        top:auto;
        bottom:auto;
        .modal-dialog {
            position:fixed;
        }
    }
    .modal-fluid {
        width: 100%;
        max-width: 100%;
        .modal-content {
            width: 100%;
        }
    }
    .modal-frame {
        position: absolute;
        width: 100%;
        max-width: 100%;
        margin: 0;
        &.modal-bottom {
            bottom: 0;
        }
    }
    .modal-full-height {
        display: flex;
        position: absolute;
        width: 400px;
        height: 100%;
        margin: 0;
        top: 0;
        // bottom: 0;
        right: 0;
        &.modal-top, &.modal-bottom {
            display:block;
            width:100%;
            max-width:100%;
            height:auto;
        }
        &.modal-top {
            bottom:auto;
        }
        &.modal-bottom {
            top:auto;
        }
        .modal-content {
            width:100%;
        }
        &.modal-lg {
            max-width:90%;
            width:90%;
            @media (min-width:992px) {
                max-width:800px;
                width:800px;  
            }
            @media (min-width:1200px) {
                max-width:1000px;
                width:1000px;  
            }
        }
    }
    .modal-side {
        position: absolute;
        right: $modal-distance;
        bottom: $modal-distance;
        margin: 0;
        width: 400px;
    }
}

// Styles
.modal-dialog {
    .btn .fa {
        color:#fff !important;
    }
    [class*="btn-outline-"] .fa {
        color: inherit !important;
    }
    // Cascading modals
    &.cascading-modal {
        margin-top: 10%;
        // Cascading header
        .modal-header {
            text-align: center;
            margin: -2rem 1rem 1rem 1rem;
            padding: 1.5rem;
            border: none;
            flex-direction: column;
            // @extend .z-depth-1-half;
            // @include border-radius(3px);
            .close {
                margin-right: 2.5rem;
            }
            &.white-text {
                .close {
                    color: #fff;
                    opacity: 1;
                }
            }
            .title {
                width: 100%;
                margin-bottom: 0;
                font-size: 1.25rem;
                .fa {
                    margin-right: 9px;
                }
            }
            .social-buttons {
                margin-top: 1.5rem;
                a {
                    font-size: 1rem;
                }
            }
        }
        // Cascading tabs nav
        .modal-c-tabs {
            .nav-tabs {
                margin: -1.5rem 1rem 0 1rem;
                // @extend .z-depth-1;
            }
            .tab-content {
                padding: 1.7rem 0 0 0;
            }
        }
        // Footer customization
        .modal-body,
        .modal-footer {
            color: #616161;
            padding-right: 2rem;
            padding-left: 2rem;
            .additional-option {
                text-align: center;
                margin-top: 1rem;
            }
        }
        // Cascading avatar
        &.modal-avatar {
            margin-top: 6rem;
            .modal-header {
                // @extend .z-depth-0;
                // @extend .img-fluid;
                margin: -6rem 2rem -1rem 2rem;
                img {
                    width: 130px;
                    // @extend .z-depth-2;
                }
            }
        }
    }
    // Modal notify
    &.modal-notify {
        .heading {
            margin: 0;
            padding: 0.3rem;
            color: #fff;
            font-size: 1.15rem;
        }
        .modal-header {
            // @extend .z-depth-1;
            border: 0;
        }
        .close {
            opacity: 1;
        }
        .modal-body {
            padding: 1.5rem;
            color: #616161;
        }
        .btn-outline-secondary-modal {
            background-color: transparent;
        }
        // Modal info
        &.modal-info {
            .modal-header {
                background-color: $modal-info-color;
            }
            .fa {
                color: $modal-info-color;
            }
            .badge {
                background-color: $modal-info-color;
            }
            .btn-primary-modal {
                background: $modal-info-color;
                &:hover,
                &:focus,
                &:active {
                    background-color: lighten( $modal-info-color, 5%)!important;
                }
                &.active {
                    background-color: darken( $modal-info-color, 20%)!important;
                    // @extend .z-depth-1-half;
                }
            }
            .btn-outline-secondary-modal {
                border: 2px solid $modal-info-color;
                color: $modal-info-color!important;
            }
        }
        // Modal warning
        &.modal-warning {
            .modal-header {
                background-color: $modal-warning-color;
            }
            .fa {
                color: $modal-warning-color;
            }
            .badge {
                background-color: $modal-warning-color;
            }
            .btn-primary-modal {
                background: $modal-warning-color;
                &:hover,
                &:focus,
                &:active {
                    background-color: lighten( $modal-warning-color, 5%)!important;
                }
                &.active {
                    background-color: darken( $modal-warning-color, 20%)!important;
                    // @extend .z-depth-1-half;
                }
            }
            .btn-outline-secondary-modal {
                border: 2px solid $modal-warning-color;
                color: $modal-warning-color!important;
            }
        }
        // Modal success
        &.modal-success {
            .modal-header {
                background-color: $modal-success-color;
            }
            .fa {
                color: $modal-success-color;
            }
            .badge {
                background-color: $modal-success-color;
            }
            .btn-primary-modal {
                background: $modal-success-color;
                &:hover,
                &:focus,
                &:active {
                    background-color: lighten( $modal-success-color, 5%)!important;
                }
                &.active {
                    background-color: darken( $modal-success-color, 20%)!important;
                    // @extend .z-depth-1-half;
                }
            }
            .btn-outline-secondary-modal {
                border: 2px solid $modal-success-color;
                color: $modal-success-color!important;
            }
        }
        // Modal danger
        &.modal-danger {
            .modal-header {
                background-color: $modal-danger-color;
            }
            .fa {
                color: $modal-danger-color;
            }
            .badge {
                background-color: $modal-danger-color;
            }
            .btn-primary-modal {
                background: $modal-danger-color;
                &:hover,
                &:focus,
                &:active {
                    background-color: lighten( $modal-danger-color, 5%)!important;
                }
                &.active {
                    background-color: darken( $modal-danger-color, 20%)!important;
                    // @extend .z-depth-1-half;
                }
            }
            .btn-outline-secondary-modal {
                border: 2px solid $modal-danger-color;
                color: $modal-danger-color!important;
            }
        }
    }
}

.modal-sm .modal-content{
  margin: 0 auto;
  max-width: 300px;
}

@media (min-width: 768px){
  .modal-sm{
    max-width: 100%;
  }
}

.modal .modal-fluid, .modal .modal-frame {
    width: 100%;
    max-width: 100%;
}

/*********************
 Modals
**********************/

// Modal Login & Modal Register
.modal-ext .modal-content {
    .modal-header {
        text-align: center;
    }
    .options {
        float: left;
    }
    .modal-body .text-xs-center fieldset {
        margin-top: 20px;
    }
    .call {
        margin-top: 1rem;
    }
    .modal-body {
        padding: 2rem 2rem 1rem 2rem;
    }
}

.modal-content {
    .close {
        position: absolute;
        right: 15px;
    }
}

// Modal Cart
.modal-cart {
    li p {
        margin: 5px;
        font-weight: 400;
        .badge {
            margin-left: 10px;
            margin-top: 3px;
            font-weight: 400;
            position: absolute;
        }
        .quantity {
            font-size: 16px;
            margin-right: 7px;
            font-weight: 300;
        }
    }
    .cartPageLink {
        margin-left: 10px;
        a {
            text-decoration: underline;
            color: #666;
        }
    }
    .total {
        float: right;
        font-weight: 400;
    }
}

// Modals normalize 
.cf-phone {
    margin-left: 7px;
}

// Container that the modal scrolls within
.side-modal {
    position: fixed;
    width: 400px;
    height: 100%;
    width: 100%;
    z-index: 9999;
    // Shell div to position the modal with bottom padding
    .modal-dialog {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 400px;
        margin: 10px;
        // @extend .z-depth-1-half;
        @media (max-width: 760px) {
            display: none;
        }
    }
    // Actual modal
    .modal-header {
        padding: 1rem;
        .heading {
            margin: 0;
            padding: 0;
        }
    }
    .modal-content {
        border: none;
    }
    // Modal background
}

.side-modal.fade:not(.show) .modal-dialog {
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
}

// Transparent backdrop
.transparent-bd {
    opacity: 0!important;
}

.modal-backdrop.in {
	opacity: 0.5;
}

.modal-backdrop {
  opacity: 0.5;
}

#exampleModalScroll {
	overflow-x: hidden;
    overflow-y: auto;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: hidden;
}