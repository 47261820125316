mdb-image-modal {
  user-select: none;
  -webkit-user-drag: none;
  .hide_lightbox {
    display: none;
  }
  .ng-gallery {
    display: flex; 
    width: auto;
    flex-wrap: wrap;
    img.ng-thumb {
      width: 100%;
      height: 100%;
      float: none;
      display: block;
      cursor: pointer;
    }
  }
  .ng-overlay { 
    outline: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 1;
    z-index: 9999;
    user-select: none;
    -webkit-user-drag: none;
    .ng-gallery-content { 
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10000;
      text-align: center;
      overflow: hidden;
      > a {
        &.close-popup {
          font-size: 42px;
          float: right;
          color: #fff;
          text-decoration: none;
          margin: 0 30px 0 0;
          cursor: pointer;
          position: absolute;
          top: 20px;
          right: 0;
        }
        &.download-image {
          font-size: 42px;
          float: right;
          color: #fff;
          text-decoration: none;
          margin: 0 30px 0 0;
          cursor: pointer;
          position: absolute;
          top: 20px;
          right: 63px;
        }
      }
      .nav-left {
        span {
          display: block;
          background-color: transparent;
          &:before {
            content: ' ';
            display: block;
            width: 32px;
            height: 30px;
            background: url('#{$image-path}lightbox/default-skin.png');
            background-position: -138px -44px;
            opacity: 0.75;
            transition: opacity 0.2s;
            background-color: transparent;
          }
        }
        &:hover span:before {
          opacity: 1;
        }
      }
      .nav-right {
        span {
          display: block;
          background-color: transparent;
          &:before {
            content: ' ';
            display: block;
            width: 32px;
            height: 30px;
            background: url(#{$image-path}/lightbox/default-skin.png);
            background-position: -94px -44px;
            opacity: 0.75;
            transition: opacity 0.2s;
            background-color: transparent;
          }
        }
        &:hover span:before {
          opacity: 1;
        }
      }
      > {
        img {
          width: 100%;
          &.smooth {
            transition: 0.2s;
          }
        }
        a {
          &.nav-left, &.nav-right {
            color: #fff;
            text-decoration: none;
            font-size: 60px;
            cursor: pointer;
            outline: none;
          }
          &.nav-left {
            position: fixed;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
          &.nav-right {
            position: fixed;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        img {
          max-width: 1600px;
          max-height: 1067px;
          user-select: none;
          -webkit-user-drag: none;
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          &.effect {
            animation: fadeIn 0.5s;
          }
        }
        span.info-text {
          color: #fff;
          display: inline-block;
          width: 100%;
          height: 20px;
          font-weight: bold;
          text-align: center;
          position: fixed;
          left: 0;
          right: 0;
          bottom: 100px;
          font-size: 16px;
        }
        .ng-thumbnails-wrapper {
          width: 400px;
          height: 70px;
          text-align: center;
          position: fixed;
          bottom: 20px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          overflow-x: hidden;
          > .ng-thumbnails {
            width: 4000px;
            height: 70px;
            > div > img {
              width: auto;
              height: 70px;
              float: left;
              margin-right: 10px;
              cursor: pointer;
              opacity: 0.6;
              &:hover, &.active {
                transition: opacity 0.25s ease;
                opacity: 1;
              }
            }
          }
        }
      }

    }
  }
}

@keyframes fadeIn {
  from { opacity: 0.3; }
    to { opacity: 1; }
}

.top-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 44px;
  width: 100%;
  z-index: 10001;
  .info-text {
    position: absolute;
    height: 44px;
    top: 0;
    left: 0;
    font-size: 16px;
    line-height: 44px;
    color: #fff;
    opacity: 0.75;
    padding: 0 10px;
  }
  .close-popup {
    position: relative;
    float: right;
    background-size: 264px 88px;
    display: block;
    width: 44px;
    height: 44px;
    background: url(#{$image-path}/lightbox/default-skin.png);
    background-position: 0 -44px;
    opacity: 0.75;
    transition: opacity 0.2s;
    &:hover {
      opacity: 1;
    }
  }
  .fullscreen-toogle {
    position: relative;
    float: right;
    background-size: 264px 88px;
    display: block;
    width: 44px;
    height: 44px;
    background: url(#{$image-path}/lightbox/default-skin.png);
    opacity: 0.75;
    transition: opacity 0.2s;
    &.toggled {
      background-position: -44px 0;
    }
    &:hover {
      opacity: 1;
    }
  }
  .zoom-toogle {
    position: relative;
    float: right;
    background-size: 264px 88px;
    display: block;
    width: 44px;
    height: 44px;
    background: url(#{$image-path}/lightbox/default-skin.png);
    background-position: -88px 0;
    opacity: 0.75;
    transition: opacity 0.2s;
    &.zoom {
      background-position: -132px 0;
    }
    &:hover {
      opacity: 1;
    }
  }
}
