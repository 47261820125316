@media (min-width: 1200px) {
    .navbar.navbar-expand-xl {
        links {
            display: flex;
            flex-direction: row;
            width: 100%;
        }
    }
}

@media (min-width: 992px) {
    .navbar.navbar-expand-lg {
        links {
            display: flex;
            flex-direction: row;
            width: 100%;
        }
    }
}

@media (min-width: 768px) {
    .navbar.navbar-expand-md {
        links {
            display: flex;
            flex-direction: row;
            width: 100%;
        }
    }
}

@media (min-width: 576px) {
    .navbar.navbar-expand-sm {
        links {
            display: flex;
            flex-direction: row;
            width: 100%;
        }
    }
}

.nav>li>a {
    padding: 10px 15px;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
}
.mg-left {
    margin-left: 15px;
}