// Card Basic
.card {
  font-weight: 400;
  border-radius: .3rem;
  &:not([class*="card-outline-"]) {
    border: 0;
    @extend .z-depth-1;
  }
  img {
    border-radius: 2px 2px 0 0;
  }
  .card-body {
    position: relative;
    h5 {
      margin-bottom: 1rem;
      font-size: 1rem;
      font-weight: 400;
    }
    h3 {
      margin-bottom: 1rem;
      font-weight: 400;
    }
    h4 {
      font-weight: 400;
    }
    p {
      margin-bottom: 1rem;
    }
  }
  .card-title {
    a {
      transition: 0.3s;
      &:hover {
        transition: 0.3s;
      }
    }
  }
  .card-text {
    font-size: 0.9rem;
    color: #747373;
    font-weight: 400;
  }
  .card-footer {
    &.links-light {
      a {
        font-size: 15px;
        color: #757575;
        &:hover {
          color: #d50000;
          transition: 0.4s;
        }
        .fa {
          font-size: 17px;
        }
      }
    }
  }
}