@media print {
  /*body * {
    visibility: hidden!important;
  }

  #lossNoticeDiv,
  #lossNoticeDiv * {
    visibility: visible !important;
  }

  #lossNoticeDiv {
    position: absolute;
    left: 0;
    top: 0;
  }*/
  header,
  footer,
  ul.nav,
  app-accident-claim-transactions {
    display: none !important;
  }

  app-accident-claim > div.row {
    display:none!important;
  }
}
