.carousel-item.active, .carousel-item-next, .carousel-item-prev {
    display: flex;
    overflow: hidden;
}

.carousel, .carousel-multi-item, .carousel-thumbnails {
    outline: none;
}

.carousel-fade .carousel-inner .carousel-item {
    opacity: 0;
    transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
    opacity: 1;
}

.carousel-fade .carousel-inner>.carousel-item.active, .carousel-fade .carousel-inner>.carousel-item.next.left, .carousel-fade .carousel-inner>.carousel-item.prev.right {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}