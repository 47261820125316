// Pricing
//Pricing Card
.pricing-card {
  text-align: center;
  p {
    font-size: 1rem;
  }
  ul {
    list-style-type:none;
    padding:0;
  }
  .header {
    border-radius:6px 6px 0 0;
    color: $white;
    @extend .z-depth-1-half;
    h4{
      padding: 2.5rem;
      font-weight: 500;
    }
  }
  .price {
    position: relative;
    padding-top: 3rem;
    h1, h2 {
      font-size: 5rem;
      padding: 1.5rem;
      font-weight:300;
      &:before {
        content: "$";
        font-size: 30px;
        position: absolute;
        margin-left: -1.2rem;
        margin-top: 0.7rem;
      }
      &:after {
        content: "/mo";
        font-size: 30px;
        position: absolute;
        margin-top: 3rem;
      }
    }
    .version {
      position: absolute;
      top: 0;
      width: 100%;
      background-color: $rgba-black-light;
      padding: 1rem;
      @extend .z-depth-1;
      h5 {
        padding: 0;
        margin: 0;
      }
    }
  }
  .heading {
    h1 {
      font-size: 4rem;
      font-weight:400;
    }
  }
  .striped {
    padding: 0.6rem;
    .fa {
      margin-right: 5px;
    }
    .fa-check {
      color: green;
    }
    .fa-times {
      color: red;
    }
    li {
      border-bottom: 1px solid rgba(153, 153, 153, 0.298039);
      margin-bottom: 1rem;
    }
  }
  // Card overlay
  &.card-overlay {
    display: block;
    color: $white;
    p {
      color: $white;
    }
    .price {
      padding-top: 0;
    }
  }
  .green-striped {
    li {
      border-color: #33c28a ;
    }
  }
  .orange-striped {
    li {
      border-color: #e97d63 ;
    }
  }
  .purple-striped {
    li {
      border-color: #963c94 ;
    }
  }
  .card-background {
    background-color: #28283f;
    border-radius: 0 0 6px 6px;
  }
}

// Additional pricing cards
.card {
  .card-circle {
    border: 2px solid #e0e0e0;
    height: 120px;
    width: 120px;
    margin-bottom: 2rem;
    @extend .flex-center;
    border-radius: 50%;
    .fa {
      font-size: 4rem;
    }
  }
}

.naked-card {
  h5 {
    margin-top: 1.2rem;
  }
  .price {
    padding-top: 0rem;
  }
  li p {
    color: color($grey, "darken-1");
    strong {
      color: color($grey, "darken-2");
    }
  }
}