// Features
.feature-box {
  text-align: center;
  .feature-title {
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .features-big {
    .feature-title {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
    .fa {
      font-size: 4rem;
    }
  }
  .features-small {
    text-align: left;
    .fa {
      font-size: 2rem;
    }
  }
}