// CAROUSELS BASIC
.carousel {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 36px;
    height: 36px;
  }
  .carousel-control-prev-icon {
    background-image: url(#{$image-path}/svg/arrow_left.svg);
  }
  .carousel-control-next-icon {
    background-image: url(#{$image-path}/svg/arrow_right.svg);
  }
  .carousel-indicators {
    li {
      max-width: 10px;
      height: 10px;
      border-radius: 50%;
      cursor:pointer;
    }
  }
  .video-fluid {
    height: 100%;
    width: 100%;
  }
}

.carousel-fade {
  .carousel-item {
    opacity: 0;
    transition-duration: .6s;
    transition-property: opacity;
  }

  .carousel-item.active,
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    opacity: 1;
  }

  .active.carousel-item-left,
  .active.carousel-item-right {
    opacity: 0;
  }

  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active,
  .active.carousel-item-left,
  .active.carousel-item-prev {
    transform: translateX(0);

    @supports (transform-style: preserve-3d) {
      transform: translate3d(0, 0, 0);
    }
  }
}