// Templates
// Fixed SideNav
.fixed-sn {
  .double-nav,
  main,
  footer {
    padding-left: 240px;
  }
  main {
    padding-top: 5.5rem;
  }
  @media (max-width: $sidenav-breakpoint) {
    .double-nav,
    main,
    footer {
      padding-left: 0;
    }
  }
  @media (min-width: $small-screen) {
    main,
    .page-footer .container-fluid {
      margin-left: 2%;
      margin-right: 2%;
    }
  }
  @media (min-width: $medium-screen) {
    main,
    .page-footer .container-fluid {
      margin-left: 5%;
      margin-right: 5%;
    }
  }
  @media (min-width: $large-screen) {
    main,
    .page-footer .container-fluid {
      margin-left: 6%;
      margin-right: 6%;
    }
  }
}

// Hidden SideNav
.hidden-sn {
  main {
    padding-top: 5.5rem;
  }
  .button-collapse {
    display: block;
    position: relative;
    font-size: 1.4rem;
    margin-right: 10px;
    margin-left: -6px;
    padding-left: 0;
  }
}