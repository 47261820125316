/***************
  MDB Autocomplete
***************/
.mdb-autocomplete {
  margin-bottom: 1px;
}

.mdb-autocomplete-clear {
  visibility: hidden;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0.85rem;
  background: transparent;
  border: none;
  svg {
    fill: #a6a6a6;
  }
}

.mdb-autocomplete-wrap {
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
  z-index: 100;
  max-height: 210px;
  overflow-y: auto;
  padding-left: 0;
  list-style-type: none;
  li {
    cursor: pointer;
    padding: 12px 15px;
    font-size: 0.875rem;
    &:hover {
      background: rgb(238, 238, 238);
    }
  }
}