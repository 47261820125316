
/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
mat-slide-toggle.mat-mdc-slide-toggle .mat-slide-toggle-bar{
  background-color: #9ebec5!important;
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
mat-slide-toggle.mat-mdc-slide-toggle .mat-slide-toggle-thumb{
  border-radius: 50%!important;
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
mat-slide-toggle.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb{
  background-color: #36496d!important;
}

app-accident-advanced-search mat-form-field input.input-date{
    color:rgb(0 0 0 / 72%)!important;
}

app-accident-advanced-search mat-form-field.select-container{
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    div.mat-form-field-underline::before{
      background-color: rgb(0 0 0 / 26%);
    }
}

custom-reports-grid-filter-modal mat-form-field input.input-date{
  color:rgb(0 0 0 / 72%)!important;
}

app-accident-claim mat-tab-group.claim-tabs-container, mat-tab-group.tabs-container{
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  mat-tab-body div.mat-tab-body-content{
    overflow: hidden;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  div.mat-tab-labels{
    background-color: #d32f2f;
    div.mat-ripple.mat-focus-indicator:hover{
      background-color: #76787a;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    div.mat-tab-label-content{
      color: white;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  mat-tab-header div.mat-tab-label-container div.mat-tab-list mat-ink-bar{
    background-color: white!important;
  }

}


//Mat-tree styles
.tree-invisible {
  display: none;
}

.tree-container ul,
.tree-container li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

/*
 * This padding sets alignment of the nested nodes.
 */
.tree-container .mat-nested-tree-node div[role=group] {
  padding-left: 40px;
}

/*
 * Padding for leaf nodes.
 * Leaf nodes need to have padding so as to align with other non-leaf nodes
 * under the same parent.
 */
.tree-container div[role=group] > .mat-tree-node {
  padding-left: 40px;
}

mat-nested-tree-node div.mat-tree-node button {
  border: none;
}

mat-nested-tree-node>div.mat-tree-node {
  font-size: 1.2em;
}

app-corporate-structure-folder mat-nested-tree-node div.mat-tree-node, app-corporate-structure-folder mat-nested-tree-node div.group mat-tree-node{
  color: #0275d8;
}

div.cdk-overlay-container div.cdk-global-overlay-wrapper div.add-group-modal mat-dialog-container{
  padding: 0px;
  overflow: hidden;
}

app-accident-claim-follow-up-date mat-form-field input.input-date{
  color:rgb(0 0 0 / 72%)!important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
app-accident-claim-supplemental-data .mat-form-field-appearance-fill .mat-form-field-flex{
  padding: 0;
}

div.cdk-overlay-container div.cdk-overlay-connected-position-bounding-box div.cdk-overlay-pane div.mat-mdc-select-panel-wrap div.mat-select-panel{
  overflow-x: hidden;
  // ::-webkit-scrollbar {
  //   width: 10px;
  //   height: 4px;
  // }

  // ::-webkit-scrollbar-button {
  //   &:start:decrement, &:end:increment {
  //     display: block;
  //     height: 0;
  //     background-color: transparent;
  //   }
  // }

  // ::-webkit-scrollbar-track-piece {
  //   background-color: transparent;
  //   border-radius: 0;
  //   border-bottom-right-radius: 4px;
  //   border-bottom-left-radius: 4px;
  // }

  // ::-webkit-scrollbar-thumb:vertical {
  //   height: 50px;
  //   background-color: #999;
  //   border-radius: 4px;
  // }
}