// Typography
// ROBOTO FONT
@font-face {
  font-family: "Roboto";
  src: local(Roboto Thin),
    url('#{$roboto-font-path}Roboto-Thin.eot');
  src: url("#{$roboto-font-path}Roboto-Thin.eot?#iefix") format('embedded-opentype'),
    url("#{$roboto-font-path}Roboto-Thin.woff2") format("woff2"),
    url("#{$roboto-font-path}Roboto-Thin.woff") format("woff"),
    url("#{$roboto-font-path}Roboto-Thin.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Roboto";
  src: local(Roboto Light),
    url('#{$roboto-font-path}Roboto-Light.eot');
  src: url("#{$roboto-font-path}Roboto-Light.eot?#iefix") format('embedded-opentype'),
    url("#{$roboto-font-path}Roboto-Light.woff2") format("woff2"),
    url("#{$roboto-font-path}Roboto-Light.woff") format("woff"),
    url("#{$roboto-font-path}Roboto-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: local(Roboto Regular),
    url('#{$roboto-font-path}Roboto-Regular.eot');
  src: url("#{$roboto-font-path}Roboto-Regular.eot?#iefix") format('embedded-opentype'),
    url("#{$roboto-font-path}Roboto-Regular.woff2") format("woff2"),
    url("#{$roboto-font-path}Roboto-Regular.woff") format("woff"),
    url("#{$roboto-font-path}Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url('#{$roboto-font-path}Roboto-Medium.eot');
  src: url("#{$roboto-font-path}Roboto-Medium.eot?#iefix") format('embedded-opentype'),
    url("#{$roboto-font-path}Roboto-Medium.woff2") format("woff2"),
    url("#{$roboto-font-path}Roboto-Medium.woff") format("woff"),
    url("#{$roboto-font-path}Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url('#{$roboto-font-path}Roboto-Bold.eot');
  src: url("#{$roboto-font-path}Roboto-Bold.eot?#iefix") format('embedded-opentype'),
    url("#{$roboto-font-path}Roboto-Bold.woff2") format("woff2"),
    url("#{$roboto-font-path}Roboto-Bold.woff") format("woff"),
    url("#{$roboto-font-path}Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
}
/*KB, PSC - remove default Roboto font*/
/*body {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}*/
body {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

@each $key, $val in $grid-breakpoints {
  @include media-breakpoint-up($key) {
    $y: map-get($responsive-headings, $key);
    @each $name,
      $value in $y {
      .#{$name}-responsive {
        font-size: $value;
      }
    }
  }
}

@each $size, $length in $spacers {
  .fs-#{$size} {
    font-size: $length !important;
  }
}

.divider-new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  margin-top: 45px;
  margin-bottom: 45px;
  h2 {
    margin-top: 5px;
  }
  &:before {
    content: '';
    height: 1.5px;
    background: #c6c6c6;
    flex: 1;
    margin: 0 .45em 0 0;
  }
  &:after {
    content: '';
    height: 1.5px;
    background: #c6c6c6;
    flex: 1;
    margin: 0 0 0 .45em;
  }
}

blockquote {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-left: .25rem solid #eceeef;
  p {
    font-size: 0.9rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
