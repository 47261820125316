@media print {
  /*body * {
    visibility: hidden!important;
  }

  #lossNoticeDiv,
  #lossNoticeDiv * {
    visibility: visible !important;
  }

  #lossNoticeDiv {
    position: absolute;
    left: 0;
    top: 0;
  }*/
  header,
  footer,
  ul.nav, div.buttonsContainer {
    display: none !important;
  }

  
}
