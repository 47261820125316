// Dropdowns free
.dropdown {
  .dropdown-menu {
    .dropdown-item {
      &:hover,
      &:active,
      &:focus {
        color: #1d1e1f;
        background-color: #f7f7f9;
      }
    }
  }
}