// Your custom style

/*StatCenter tile bar */
.grey-skin .card-header {
  background-color: #d23138;
  padding: 0.5rem 1rem;
}

.grey-skin .card-header .panel-title {
  color: white;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
}

/* Remove all rounded corners*/
* {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

/* Put back rounded corner on loading spinner only*/
.preloader-wrapper .spinner-layer .circle-clipper .circle {
  border-radius: 50% !important;
}

/* Change all link colors */
a {
  color: #9ebec5;
  }

  /*CUSTOM CHANGE TO LEFT NAV*/
  /*Changing left nav to darker grey
   
  */
.grey-skin .side-nav {
  background-color: #44474b;
}
  /* Hover header color lighter*/
  .grey-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: #a5a4a4;
  }
  /*Hover header text white*/
  .grey-skin .side-nav .collapsible .card-body li a:hover {
    color: white !important;
  }
  /*Header
    KB changed 2/14/19 from 282a2d to 44474b
  */
  .grey-skin .side-nav .collapsible .card-header a h5 {
    background-color: #44474b;
  }

  .grey-skin .side-nav .collapsible .card.active .card-header a h5 {
    background-color: #44474b !important;
  }
  /*END CUSTOM CHANGE TO LEFT NAV*/
  /*.grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover, .grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus, .grey-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #9ebec5;
}*/
  /*CUSTOM TO NAV BAR*/
  .navbar {
    padding: 0;
  }

  /* Fix to wrong hover colors on nav bar*/
  .nav > li > a:hover {
    text-decoration: none;
    background-color: #76787a;
  }

  .nav > li > a:focus {
    background-color: transparent;
  }

  /*END CUSTOM TO NAV BAR*/

  .grey-skin .btn-primary {
    background-color: #36496d;
  }

  .grey-skin .btn-primary:focus, .grey-skin .btn-primary:hover, .grey-skin .btn-primary:active {
    background-color: #9ebec5 !important;
  }

  .grey-skin .btn-default {
    background-color: #76787a;
  }

  .grey-skin .btn-default:focus, .grey-skin .btn-default:hover, .grey-skin .btn-default:active {
    background-color: #2e2a26 !important;
  }


  #gridster {
    background-color: #a5a4a4;
  }

  #body {
    background-color: #e3e4e4;
  }
  //Toggle switches when checked
  .switch label input[type="checkbox"]:checked + .lever::after {
    background-color: #36496d;
  }
  //Toggle switches when checked
  .switch label input[type="checkbox"]:checked + .lever {
    background-color: #9ebec5;
  }
