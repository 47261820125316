/* You can add global styles to this file, and also import other style files */
@use 'assets/loss-notice-print.scss';
@use 'assets/stat-center-print.scss';

@use'./assets/angular-material.scss';

html {
  font-size: 16px;
}

.scroll-to-top-custom {
  position: fixed;
  cursor: pointer;
  outline: none;
  top: 90%;
  bottom: 10%;
  right: 0;
  width: auto;
  height: 35px;
  line-height: 35px;
  text-decoration: none;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 0px 0 0 0px;
  z-index: 9999;
}


#reportFooter .left {
  float: left;
}

#reportFooter .right {
  float: right;
}

table.table thead th {
  border-top: none;
  background-color: #d23138 !important;
  color: white;
  font-weight: bold;
  border: 1px solid #ddd;
}

table.table tbody td {
  border: 1px solid #ddd;
}


table.table {
  border: 1px solid #ddd;
}

  table.table tbody td.clickable span {
    color: #d23138;
    cursor: pointer;
  }


  tr.dhp-document-validation-grid-alert {
    td:first-child {
      span::after {
        content: "\f071";
        margin-left: 5px;
        font-family: 'Font Awesome 5 Pro';
        font-style: normal;
        font-weight: bold;
        color: #000000;
      }
    }
  }

  div.document-validation-review-alert {
    span {
      background-color: #F5DA5A;
    }
  }

  table.table tbody td.clickable span:hover {
    text-decoration: underline;
  }

 table.table tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  table.table tfoot.gt-totals td {
    font-weight: bold;
  }

  app-data-table table thead tr th.sort-enable, th.sort-enable {
    cursor: pointer;
  }

  app-data-table table thead tr th.sort-enable:hover, th.sort-enable:hover {
    text-decoration: underline;
  }

  app-data-table table thead tr th.sort-asc {
    cursor: pointer;
  }

  app-data-table table thead tr th.sort-asc:hover {
    text-decoration: underline;
  }

  app-data-table table thead tr th.sort-asc::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #FFF;
    border-radius: 2px;
    margin-left: 4px;
    vertical-align: middle;
  }

  app-data-table table thead tr th.sort-desc {
    cursor: pointer;
  }

  app-data-table table thead tr th.sort-desc:hover {
    text-decoration: underline;
  }

  app-data-table table thead tr th.sort-desc::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #FFF;
    border-radius: 2px;
    margin-left: 4px;
    vertical-align: middle;
  }



.dashboardFilterPanelActionBar {
  font-size: 15px;
  position: fixed;
  top: 55px;
  right: 0;
  padding: 0.8em, 0.8em, 0.8em, 10px;
}

.navbar-nav > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
}

nav-menu ul {
  list-style: none;
}

/* 2020-04 adjustments related to MDBootstrap update*/

mdb-select > div > div.single {
  /*set height to same height as form-control, so mdb-selects will size and align correctly*/
  height: 34px !important;
  border-bottom: 1px solid #bdbdbd !important;
  padding-top: 1rem !important;
  padding-bottom: .3rem !important;
  box-shadow: none;
}

  mdb-select > div > div.single > div.placeholder {
    /* color should be same as input placeholder*/
    color: rgb(134, 142, 150) !important;
  }

mdb-select > mdb-select-dropdown > div.dropdown-content > div.filter > input.search {
  padding-bottom: 1rem;
}

  mdb-select > mdb-select-dropdown > div.dropdown-content > div.filter > input.search:focus {
    border-style: none;
    box-shadow: none;
  }

.classic-tabs .nav li a{
  padding: 20px 24px !important;
}

mdb-date-picker > div.mydp:not(.picker--opened){
  /* set datepicker z-index to 0 when not opened */
  z-index:0 !important;
}

/* END adjustments related to MDBootstrap update*/
.mvrContestButton {
  background-color: #d23138;
}

/* 2020-04 to resolve boostrap formatting issus in Safari*/
.row:before, .row:after {
  display: flex !important;
}

.navbar li{
  list-style: none;
}
.navbar {
   background-color: #44474b!important;
}

nav-menu mat-accordion mat-expansion-panel {
  mat-expansion-panel-header {
    background-color: #44474b;
    span mat-panel-title{
      color: white!important;
      font-size: 0.9em;
      font-weight: 300;
      i {
        margin-right: 1em;
      }
    }
    span.mat-expansion-indicator::after{
      color: white;
    }
  }
  mat-expansion-panel-header.mat-expansion-panel-header {
    background-color: #44474b!important;
    mat-panel-title:hover {
      text-decoration: underline;
    }
  }
  div.mat-expansion-panel-content{
    background-color: #76787a!important;
    div.mat-expansion-panel-body {
      padding: 0;
      ul {
        margin: 0 0 0 2em;
        padding: 0;
        font-weight: 300;
        li {
          margin-top: 0.5em;
          margin-bottom: 0.5em;
        }
      }
      client-selector ul {
        margin-left: 0!important;
      }
      a{
        color: white;
      }
      a:hover {
        color: white;
        text-decoration: underline;
      }
    }
  }
}

.add-document-modal mat-dialog-container{
  padding: 0;
}

.alert-modal mat-dialog-container {
  padding: 0;
  z-index: 999;
}

.dashboard-tile-zoom mat-dialog-container{
  padding: 0;
  overflow: hidden;
}

.custom-reports-filter-modal mat-dialog-container {
  padding: 0;
  overflow: hidden;
}

div.cdk-global-overlay-wrapper div.cdk-overlay-pane .mat-mdc-snack-bar-container {
  margin: 1.5rem 1.5rem 0 0;
}

div.cdk-global-overlay-wrapper div.cdk-overlay-pane .mat-mdc-snack-bar-container.success-snackbar{
  color: white!important;
  white-space: pre-wrap;

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  span.mat-button-wrapper, .mdc-button__label {
    color: white!important;
  }
  .mdc-snackbar__surface {
    background-color: #0fd50fa6!important;
  }
}

div.cdk-global-overlay-wrapper div.cdk-overlay-pane .mat-mdc-snack-bar-container.warning-snackbar{
  color: white!important;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  span.mat-button-wrapper, .mdc-button__label {
    color: white!important;
  }
  .mdc-snackbar__surface {
    background-color: #eed202!important;
  }
}

div.cdk-global-overlay-wrapper div.cdk-overlay-pane .mat-mdc-snack-bar-container.error-snackbar{
  color: white!important;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  span.mat-button-wrapper, .mdc-button__label {
    color: white!important;
  }
  .mdc-snackbar__surface {
    background-color: rgba(#d51c0f, 0.8)!important;
  }
}

app-dc-document-library mat-expansion-panel-header, app-dc-document-library .mat-expansion-panel-header:hover:not([aria-disabled=true]),
app-safety-employee-driver-form mat-expansion-panel-header, app-safety-employee-driver-form .mat-expansion-panel-header:hover:not([aria-disabled=true]),
app-safety-mvr-release-req mat-expansion-panel-header, app-safety-mvr-release-req .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background-color: #d23138!important;

  mat-panel-title,span.mat-expansion-indicator::after{
    color: white!important;
    font-weight: 700;
  }
}

app-safety-employee-driver-form mat-expansion-panel {
  width: 98%;
  margin-bottom: 0.3em!important;
}

app-data-grid-per-client mat-expansion-panel-header, app-widget-per-client mat-expansion-panel-header {
  mat-panel-title,span.mat-expansion-indicator::after{
    color: #d23138!important;
  }
}

mat-tab-group mat-tab-header {
  background-color: #d23138;

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  div.mat-mdc-tab-label-container {
    margin-left: 2em !important;
    mat-ink-bar {
      background-color: white!important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    div.mat-mdc-tab-list div.mat-mdc-tab-labels div.mat-mdc-tab-label-content, span.mdc-tab__text-label {
      color: white !important;
    }
    .mdc-tab-indicator__content--underline {
      border-color: white !important;
    }
  }
}

mat-stepper mat-step-header div.mat-step-icon{
  border-radius: 50%!important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
quick-search-menu .quickSearchRefine div.mat-form-field-wrapper div.mat-form-field-flex{
  padding: 0;
}

safety-additional-drivers-not-full {
  mat-expansion-panel-header span.mat-expansion-indicator::after, div.title{
    color: #0275d8;
  }
  mat-panel-title {
    width: 43em;
  }
}

app-accident-claim-arbitration .pHeader {
  background-color: #d23138!important;
}

app-accident-claim-arbitration .pTitle {
 color: white!important;
}

.mvr-tools-container, .main-container {
  a {
    padding: 0!important;
  }
  li {
    margin-bottom: 0!important;
  }
}

app-mvr-export mat-stepper div.mat-horizontal-stepper-header-container, app-cov-export mat-stepper div.mat-horizontal-stepper-header-container{
  mat-step-header div.mat-step-icon {
    width: 2em;
    height: 2em;
  }
  div.mat-stepper-horizontal-line {
    width: 10em;
    flex: none;
  }
}

::ng-deep .mat-form-field-flex > .mat-form-field-infix { padding: 0.4em 0px !important;}
::ng-deep .mat-form-field-label-wrapper { top: -1.5em; }

::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(.75);
    width: 133.33333%;
}

mat-form-field.small {
  --mat-form-field-container-height: 40px;
  --mat-form-field-container-vertical-padding: 10px;
}

.modal-body {
  padding: 1rem !important;
}

.modal-footer {
  padding: .75rem !important;
}

select.form-control[size], select.form-control[multiple], textarea.form-control {
  height: auto;
}

.modal-header {
  button.close {
    background: none;
    border: none;
    font-weight: bold;
    font-size: 26px;
  }
}

.dropdown-item {
  padding: .25rem 1.5rem;
  font-weight: normal;
  color: #212529;

  &.hover,
  &:hover {
    background: rgb(233,233,233);
    background: linear-gradient(90deg, rgba(233,233,233,1) 100%, rgba(248,249,250,1) 100%);
    text-decoration: none !important;
  }

  &.active,
  &:active {
    color: white;
  }

  &.disabled,
  &:disabled {
    color: #868e96;
  }
}

.mat-mdc-menu-content {
  span:hover {
    text-decoration: none !important;
  }
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;

  &.hover,
  &:hover {
    text-decoration: none;
    opacity: 1;
  }
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

// stylelint-disable-next-line selector-no-qualifying-type
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

// Future-proof disabling of clicks on `<a>` elements

// stylelint-disable-next-line selector-no-qualifying-type
a.close.disabled {
  pointer-events: none;
}

div.switch {
  label {
    margin-bottom: .5rem;
  }
}

.quickSearchContainer, .quickSearchContainerDashboard, .quickSearchContainerDataGrid {
  margin-bottom: 2rem;
}

.g-bubble-container {
  display: flex;
  gap: 4px;
}

.g-bubble {
  background-color: #919191;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}

.g-bubble:hover {
  background-color: #b6b6b6;
  color: white;
}

.g-bubble__active {
  background-color: #6193a1;
  color: white;
}

/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
mat-paginator {
  background: transparent !important;
}

.mat-mdc-paginator-range-actions {
  margin-right: 12px !important;
}
