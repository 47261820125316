// Distance
$modal-distance:10px;
$modal-info-color: #5394ff;
$modal-success-color: #01d36b;
$modal-warning-color: #ff8e38;
$modal-danger-color: #ff4b4b;

// Styles for body
body {
  &.modal-open {
    padding-right: 0 !important;
    overflow: auto;
  }
  &.scrollable {
    overflow-y: auto;
  }
}

// *** ENHANCED BOOTSTRAP MODALS ***///
// General styles
.modal-dialog {
  .modal-content {
    @include border-radius(2px);
    @extend .z-depth-1-half;
    border: 0;
  }
}

// Position & Size
.modal {
  padding-right:0 !important;
  .modal-dialog {
    @media (min-width: 768px) {
      &.modal-top {
        top: 0;
      }
      &.modal-left {
        left: 0;
      }
      &.modal-right {
        right: 0;
      }
      &.modal-bottom {
        bottom: 0;
      }
      &.modal-top-left {
        top: $modal-distance;
        left: $modal-distance;
      }
      &.modal-top-right {
        top: $modal-distance;
        right: $modal-distance;
      }
      &.modal-bottom-left {
        left: $modal-distance;
        bottom: $modal-distance;
      }
      &.modal-bottom-right {
        right: $modal-distance;
        bottom: $modal-distance;
      }
    }
  }
  &.fade {
    &.top:not(.show) .modal-dialog {
      transform: translate3d(0, -25%, 0);
    }
    &.left:not(.show) .modal-dialog {
      transform: translate3d(-25%, 0, 0);
    }
    &.right:not(.show) .modal-dialog {
      transform: translate3d(25%, 0, 0);
    }
    &.bottom:not(.show) .modal-dialog {
      transform: translate3d(0, 25%, 0);
    }
  }
  @media (min-width: $medium-screen) {
    &.modal-scrolling {
      position: relative;
      .modal-dialog {
        position: fixed;
        z-index: 1050;
      }
    }
    &.modal-content-clickable {
      top:auto;
      bottom:auto;
      .modal-dialog {
        position:fixed;
      }
    }
    .modal-fluid {
      width: 100%;
      max-width: 100%;
      .modal-content {
        width: 100%;
      }
    }
    .modal-frame {
      position: absolute;
      width: 100%;
      max-width: 100%;
      margin: 0;
      &.modal-bottom {
        bottom: 0;
      }
    }
    .modal-full-height {
      display: flex;
      position: absolute;
      width: 400px;
      height: 100%;
      margin: 0;
      top: 0;
      right: 0;
      &.modal-top, &.modal-bottom {
        display:block;
        width:100%;
        max-width:100%;
        height:auto;
      }
      &.modal-top {
        bottom:auto;
      }
      &.modal-bottom {
        top:auto;
      }
      .modal-content {
        width:100%;
      }
      &.modal-lg {
        max-width:90%;
        width:90%;
        @media (min-width:$medium-screen) {
          max-width:800px;
          width:800px;
        }
        @media (min-width:$large-screen) {
          max-width:1000px;
          width:1000px;  
        }
      }
    }
    .modal-side {
      position: absolute;
      right: $modal-distance;
      bottom: $modal-distance;
      margin: 0;
      width: 400px;
    }
  }
}

// Styles
.modal-dialog {
  .btn .fa {
    color:#fff !important;
  }
  [class*="btn-outline-"] .fa {
    color: inherit !important;
  }
  // Cascading modals
  &.cascading-modal {
    margin-top: 10%;
    .close {
      color:#fff;
      opacity:1;
      text-shadow:none;
    }
    // Cascading header
    .modal-header {
      text-align: center;
      margin: -2rem 1rem 1rem 1rem;
      padding: 1.5rem;
      border: none;
      @extend .z-depth-1-half;
      @include border-radius(3px);
      .close {
        margin-right: 1rem;
      }
      .title {
        width: 100%;
        margin-bottom: 0;
        font-size: 1.25rem;
        .fa {
          margin-right: 9px;
        }
      }
      .social-buttons {
        margin-top: 1.5rem;
        a {
          font-size: 1rem;
        }
      }
    }
    // Cascading tabs nav
    .modal-c-tabs {
      .nav-tabs {
        margin: -1.5rem 1rem 0 1rem;
        @extend .z-depth-1;
      }
      .tab-content {
        padding: 1.7rem 0 0 0;
      }
    }
    .nav-tabs {
      display:flex;
      li {
        flex:1;
        a {
          text-align:center;
          font-weight:normal;
        }
      }
    }
    // Footer customization
    .modal-body,
    .modal-footer {
      color: #616161;
      padding-right: 2rem;
      padding-left: 2rem;
      .additional-option {
        text-align: center;
        margin-top: 1rem;
      }
    }
    // Cascading avatar
    &.modal-avatar {
      margin-top: 6rem;
      .modal-header {
        @extend .z-depth-0;
        @extend .img-fluid;
        margin: -6rem 2rem -1rem 2rem;
        img {
          width: 130px;
          @extend .z-depth-2;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  // Modal notify
  &.modal-notify {
    .heading {
      margin: 0;
      padding: 0.3rem;
      color: #fff;
      font-size: 1.15rem;
    }
    .modal-header {
      @extend .z-depth-1;
      border: 0;
    }
    .close {
      opacity: 1;
    }
    .modal-body {
      padding: 1.5rem;
      color: #616161;
    }
    .btn-outline-secondary-modal {
      background-color: transparent;
    }
    // Modal info
    &.modal-info {
      .modal-header {
        background-color: $modal-info-color;
      }
      .fa {
        color: $modal-info-color;
      }
      .badge {
        background-color: $modal-info-color;
      }
      @include make-button('primary-modal', $modal-info-color);
      @include make-outline-button('secondary-modal', $modal-info-color);
    }
    // Modal warning
    &.modal-warning {
      .modal-header {
        background-color: $modal-warning-color;
      }
      .fa {
        color: $modal-warning-color;
      }
      .badge {
        background-color: $modal-warning-color;
      }
      @include make-button('primary-modal', $modal-warning-color);
      @include make-outline-button('secondary-modal', $modal-warning-color);
    }
    // Modal success
    &.modal-success {
      .modal-header {
        background-color: $modal-success-color;
      }
      .fa {
        color: $modal-success-color;
      }
      .badge {
        background-color: $modal-success-color;
      }
      @include make-button('primary-modal', $modal-success-color);
      @include make-outline-button('secondary-modal', $modal-success-color);
    }
    // Modal danger
    &.modal-danger {
      .modal-header {
        background-color: $modal-danger-color;
      }
      .fa {
        color: $modal-danger-color;
      }
      .badge {
        background-color: $modal-danger-color;
      }
      @include make-button('primary-modal', $modal-danger-color);
      @include make-outline-button('secondary-modal', $modal-danger-color);
    }
  }
}