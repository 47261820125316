.z-depth-0 {
  box-shadow: none !important;
}

.z-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.z-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.z-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.hoverable {
  @include transition(box-shadow .55s);
  box-shadow: 0;
  &:hover {
    @include transition(box-shadow .45s);
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

a {
  color: $link-color;
  text-decoration: none;
  cursor: pointer;
  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

//Disabled cursor
.disabled {
  cursor: not-allowed!important;
}

// .list-group,
.jumbotron,
.navbar,
.badge,
.chip {
  @extend .z-depth-1;
}

.list-group .list-group-item,
.jumbotron,
.navbar-toggler,
.badge { 
  @include border-radius(2px);
}

.flex-center {
  ul {
    list-style-type:none;
    padding:0;
  }
}

// Media
.media {
  .avatar-sm {
    width: 56px;
    border-radius: 50%; 
  }
}