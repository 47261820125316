// CAROUSELS PRO
.carousel-multi-item {
  @media (min-width: 768px) {
    .col-md-4 {
      float: left;
      width: 33.333333%;
      max-width: 100%;
    }
  }

  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }

  margin-bottom: 100px;
  .carousel-indicators li {
    height: 20px;
    max-width: 20px;
    background-color: $primary-color;
    margin-bottom: -60px;
  }
  .carousel-indicators .active {
    height: 25px;
    max-width: 25px;
    background-color: $primary-color;
    @include border-radius(50%);
  }
  .controls-top {
    text-align: center;
    margin-bottom: 30px;
    .btn-floating {
      background: $primary-color;
    }
  }
  .carousel-indicators {
    margin-bottom: -2em;
  }
  .card {
    margin: 1px;
  }
  .card-cascade.narrower {
    margin-top: 20px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: $medium-screen) {
  .carousel-multi-item {
    .carousel-indicators li {
      margin-bottom: -30px;
    }
  }
}

//Carousel with thumbnails
.carousel-thumbnails {
  margin-bottom: 80px;
  .carousel-indicators {
    margin-bottom: -75px;
    position: absolute;
    li {
      height: auto;
      max-width: 100px;
      width: 100px;
      border: none;
    }
    .active {
      height: auto;
      width: auto;
      @extend .white;
      opacity: 0.5;
    }
    img {
      max-width: 100px;
      height: 50px;
      overflow: hidden;
      display: block;
    }
  }
}

@media only screen and (max-width: $small-screen) {
  .carousel-thumbnails {
    margin-bottom: 60px;
    .carousel-indicators {
      margin-bottom: -60px;
      img {
        max-width: 50px;
        height: 25px;
      }
    }
  }
}