//input disabled
.disabled {
    cursor: not-allowed!important;
}


//input counter
.counter-danger {
	border-bottom: 1px solid #F44336 !important;
	box-shadow: 0 1px 0 0 #F44336 !important;
}

input:focus ~ .chars {
	float: right;
    clear: both;
    display: block;
}

textarea:focus ~ .chars {
	float: right;
    clear: both;
    display: block;
}


//active styles for input label
.md-form label.active {
    color: #757575;
}


//inputs validation
.counter-success{
	border-bottom: 1px solid $input-success-color !important;
  	box-shadow: 0 1px 0 0 $input-success-color !important;
}

.inputVal {
	font-size: .8rem;
	position: absolute;
	top: 60px;
}

.md-form .prefix ~ .inputVal{
	margin-left: 3rem;
}

input.counter-danger ~ span.text-danger {
	visibility: visible !important;
}

input.counter-success ~ span.text-success {
	visibility: visible !important;
}
